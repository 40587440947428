import { Button, Collapsible } from "@shopify/polaris";
import React from "react";
import { v1 } from "uuid";
import { Product } from "../../../../core/products/domain/Product";
import { ProductCollapsibleProps } from "./ProductCollapsibleProps";
import { ProductCollapsibleState } from "./ProductCollapsibleState";

export class ProductCollapsible extends React.Component<ProductCollapsibleProps, ProductCollapsibleState>{ 
    constructor(props: any){
        super(props);
        this.state = {
            opened: false
        }
    }
    render(){
        return (
            <>
                <Button onClick={() => {
                    this.setState({
                        opened: !this.state.opened
                    })
                }}>Ver productos</Button>
                <Collapsible open={this.state.opened} id="basic-collapsible">
                    <table  style={{width: "100%"}}>
                        <thead>                        
                        <tr>
                            <th>SKU</th>
                            <th>Nombre</th>
                        </tr>
                        </thead>
                        <tbody>
                        {this.props.list.map((e: Product) => 
                            <tr style={{padding: "8px"}} key={v1()}>
                                <td style={{padding: "8px" , border: "solid 1px"}}>{(e.sku != null && e.sku.length != 0) ? e.sku : "No sku"}</td>
                                <td style={{padding: "8px" , textOverflow: "ellipsis", border: "solid 1px"}}>{e.name}</td>
                            </tr>
                        )}
                        </tbody>
                    </table>
                </Collapsible>
            </>
        );
    }


}