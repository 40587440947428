import { Select, SelectGroup, SelectOption } from '@shopify/polaris';
import React, { useCallback, useState } from 'react';
import { Shop } from '../../../core/shops/domain/Shop';
import ShopsApi from '../../../data/shops/shops_api';
import { ShopSelectorProps } from './ShopSelectorProps';
import { ShopSelectorState } from './ShopSelectorState';

export class ShopSelector extends React.Component<ShopSelectorProps, ShopSelectorState> {
    constructor(props: any){
        super(props);
        this.bindMethods();        
        this.state = {
            selectedShop: undefined
        }
    }
    public get options(): SelectOption[] {
        let options = this.props.shops.map((e: Shop) => {
            return { label: e.name , value: e.name }
        });
        options.unshift({
            label: "Todas las tiendas" , 
            value: "Todas las tiendas"
        });
        return options;
    }
    public handleSelectionChange(selected: string, id: string){
        let selectedShop = this.props.shops.find((value: Shop) => value.name == selected);
        this.setState({ selectedShop: selectedShop });
        this.props.onShopSelected(selectedShop);
    }
    private bindMethods(){
        this.handleSelectionChange = this.handleSelectionChange.bind(this);
    }
    
    public get value() : string {
        return typeof this.state.selectedShop == undefined || !this.state.selectedShop ? "Todas las tiendas" : this.state.selectedShop?.name;
    }
    
    public render(){
        return ( <Select value={this.value} label="Filtrar por tienda" options={this.options} onChange={this.handleSelectionChange}/> );
    }
}