import { formatDiagnostic } from "typescript";
import { Shop } from "../../../shops/domain/Shop";
import { Product } from "../../domain/Product";
import { ProductRepository } from "../../domain/ProductRepository";

export class ApiProductRepository extends ProductRepository {
    private _url: string = "https://api.multistorefy.appsbealion.com/api/admin/product";
    public async imageOfProduct(shop: Shop, id: string): Promise<any>{
        let url = this._url + "/thumbnail/" + shop.name + "/" + id;
        let response: Response = await fetch(url, {            
            headers: {
                'X-Token': localStorage.getItem("token") ?? ""
            }
        });        
        let thumbnailsJson     = await response.json();                
        if(thumbnailsJson["thumbnails"]["error"]){
            return "";
        }
        let thumbnail          = thumbnailsJson["thumbnails"][0];
        return thumbnail["src"];             
    }
    public async stock(product: Product, stock: number){
        let url = this._url + "/stock";
        var form: FormData = new FormData();
        form.append("product_sku", product.sku);
        form.append("stock", stock.toString());
        let response: Response = await fetch(url, {
            method: "POST",
            body: form,
            headers: {                
                'X-Token': localStorage.getItem("token") ?? ""
            }
        });        
    }    
    public async minusStock(sku: string){
        let url = this._url + "/minus_stock";
        var form: FormData = new FormData();
        form.append("product_sku", sku);
        let response: Response = await fetch(url, {
            method: "POST",
            body: form,
            headers: {                
                'X-Token': localStorage.getItem("token") ?? ""
            }
        });        
    }    
    public async getRawProducts(): Promise<Product[]> { 
        let url = this._url + "/list";        
        let response: Response = await fetch(url, {
            headers: {
                'X-Token': localStorage.getItem("token") ?? ""
            }
        });
        if(response.status !== 200){
            throw Error("Error");
        }    
        let json = await response.json();
        return json.map((e: any) => new Product("" , e["sku"], e["price"], e["name"], e["stock"]));
    }
    public async ofShop(shop: Shop): Promise<Product[]> { 
        let url = this._url + "/ofshop/" + shop.name;
        let response: Response = await fetch(url, {
            headers: {
                'X-Token': localStorage.getItem("token") ?? ""
            }
        });
        if(response.status !== 200){
            return [];
        }    
        let json = await response.json();
        return json.map((p: any) => p["variants"].map((e: any) => new Product(p["id"], e["sku"], e["price"], p["title"], e["stock"]))).flat();
    }
}