export class ShippingAddress{
    private _address1: string;
    private _city: string;
    private _countrty: string;
    private _countryCode: string;
    private _firstName: string;
    private _lastName: string;
    private _name: string;
    private _phone: string;
    private _zip: string;
    constructor(
        address1: string, 
        city: string,
        country: string,
        countryCode: string,
        firstName: string, 
        lastName: string, 
        name: string, 
        phone: string, 
        zip: string
    ){
        this._address1 = address1;
        this._city = city;
        this._countrty = country;
        this._countryCode = countryCode;
        this._firstName = firstName;
        this._lastName = lastName;
        this._name = name;
        this._phone = phone;
        this._zip = zip;
    }

    public get address1(): string{
        return this._address1;
    }
    public get city(): string{
        return this._city;
    }
    public get countrty(): string{
        return this._countrty;
    }
    public get countryCode(): string{
        return this._countryCode;
    }
    public get firstName(): string{
        return this._firstName;
    }
    public get lastName(): string{
        return this._lastName;
    }
    public get name(): string{
        return this._name;
    }
    
    public get fullName() : string {
        return this._name;
    }
    
    public get phone(): string{
        return this._phone;
    }
    public get zip(): string{
        return this._zip;
    }    
    
    public get fullAddress() : string {
        return (this._address1 + "," + this._city + "," + this._zip).replaceAll(',null' , '');
    }
    

    static fromJson(json: any){
        return new ShippingAddress(
            json["address1"] , 
            json["city"] , 
            json["country"] , 
            json["country_code"] , 
            json["first_name"] , 
            json["last_name"] , 
            json["name"] , 
            json["phone"] , 
            json["zip"]
        )
    }

    toJson(){
        return {
            "address1": this._address1 , 
            "city": this._city , 
            "country": this._countrty , 
            "first_name": this._firstName , 
            "last_name": this._lastName , 
            "name": this._name , 
            "phone": this._phone , 
            "zip": this._zip
        }
    }

}