import { Order } from "../../domain/Order";
import { OrderRepository } from "../../domain/OrderRepository";

export class OrderCanceler{
    private _repository: OrderRepository;
    constructor(repository: OrderRepository){
        this._repository = repository;
    }
    invoke(order: Order){
        return this._repository.cancel(order);
    }
}