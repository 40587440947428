import { Pagination } from '@shopify/polaris';
import React, { useCallback, useState } from 'react';
import { OrdersPaginatorProps } from './OrdersPaginatorProps';
import { OrdersPaginatorState } from './OrdersPaginatorState';


export class OrdersPaginator extends React.Component<OrdersPaginatorProps, OrdersPaginatorState> {
    constructor(props: any){
        super(props);
        this.state = {
            page: 1
        }
        this.bindAll();
    }
    render() {
        return (
            <Pagination
            label={this.actualPage + " - " + this.totalPages + " | " + "Total:" + this.props.orders.length.toString()}
            hasPrevious
            onPrevious={this.previousPage}
            hasNext
            onNext={this.nextPage}
            />
        );
    }

    public nextPage(){
        let newpage = this.state.page + 1;
        if(newpage > this.totalPages){
            newpage = 1;
        }
        this.setState({
            page: newpage
        })
        this.props.onPageChange(newpage);
    }
    public previousPage(){
        let newpage = this.state.page - 1;
        if(newpage == 0 ){
            newpage = this.totalPages;
        }
        this.setState({
            page: newpage
        });
        this.props.onPageChange(newpage);
    }
    public bindAll(){
        this.nextPage = this.nextPage.bind(this);
        this.previousPage = this.previousPage.bind(this);
    }
    public get totalPages() : number {
        return Math.ceil(this.props.orders.length / 10);
    }
    
    public get actualPage() : number {
        return this.state.page;
    }
    
    public get total() : string {
        return this.props.orders.length.toString();
    }
    

}   