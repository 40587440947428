import { Shop } from "../../../shops/domain/Shop";
import { Order } from "../../domain/Order";
import { OrderRepository } from "../../domain/OrderRepository";
import { Tracking } from "../../domain/Tracking";

export class TrackingOrderUpdater{
    private _repository: OrderRepository;
    constructor(repository: OrderRepository){
        this._repository = repository;
    }
    invoke(order: Order, tracking: Tracking){
        return this._repository.addTracking(order, tracking);
    }
}