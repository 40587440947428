import { Badge, Button, Card, DisableableAction, Modal, ResourceItem, ResourceList, TextField, TextStyle } from '@shopify/polaris';
import React, { useCallback, useState } from 'react';
import { ShopConfirmer } from '../../core/shops/application/confirm/ShopConfirmer';
import { ShopDeleter } from '../../core/shops/application/delete/ShopDeleter';
import { ShopNoteAdder } from '../../core/shops/application/note/ShopNoteAdder';
import { Shop } from '../../core/shops/domain/Shop';
import { ShopRepository } from '../../core/shops/domain/ShopRepository';
import { ApiShopRepository } from '../../core/shops/infrastructure/api/ApiShopRepository';
import { ApiKeyField } from '../Shared/ApiKeyField/ApiKeyField';
import { ShopApiKeys } from '../Shared/ShopApiKeys/ShopApiKeys';
import { ShopsProps } from './ShopsProps';
import { ShopsState } from './ShopsState';

export class Shops extends React.Component<ShopsProps, ShopsState>{
    private _repository: ShopRepository = new ApiShopRepository();
    private _confirmer: ShopConfirmer;
    private _noteAdder: ShopNoteAdder;
    private _deleter: ShopDeleter;
    constructor(props: any){
        super(props);
        this.state = {
            selectedItems: [],
            modalNotesOpened: false,
            modalNoteShop: null,
            note: ""
        }
        this._confirmer = new ShopConfirmer(this._repository);
        this._noteAdder = new ShopNoteAdder(this._repository);
        this._deleter   = new ShopDeleter(this._repository);
        this.onConfirmClick = this.onConfirmClick.bind(this);
        this.onDeleteClick = this.onDeleteClick.bind(this);
        this.addNote = this.addNote.bind(this);
        this.renderItem = this.renderItem.bind(this);
    }

    render() {
        return (
            <div style={{ margin: "8px" }}>
                <Card>
                    <div style={{ padding: "8px" }}>
                        <ApiKeyField></ApiKeyField>
                        {/* <ShopApiKeys onAdded={() => {
                            this.props.onAdded();
                        }}></ShopApiKeys> */}
                    </div>
                </Card>
                <Card>
                    <div style={{ padding: "8px" }}>
                        <ResourceList
                            resourceName={{
                                singular: "Tienda",
                                plural: "Tiendas"
                            }}
                            items={this.props.shops}
                            renderItem={this.renderItem}
                            selectedItems={this.state.selectedItems}
                            onSelectionChange={(value: any) => {                                                                
                               value = value[0] == "0" ? "All" : value;                               
                               this.setState({
                                   selectedItems: value
                               })
                            }}
                            resolveItemId={(item: Shop) => item.id}
                            bulkActions={this.bulkActions}
                        />
                    </div>
                </Card>
                <Modal title={"Añadir nota para " + this.state.modalNoteShop?.name}  onClose={() => {
                    this.setState({
                        modalNotesOpened: false
                    });
                }} open={this.state.modalNotesOpened}>
                    <Modal.Section>
                        <TextField multiline={true} value={this.state.note} label="Nota" onChange={(value: string) => {
                            this.setState({
                                note: value
                            });
                        }}/>
                        <div style={{marginTop: "8px"}}>
                            <Button onClick={() => {
                                if(this.state.modalNoteShop !== null){
                                    this._noteAdder.invoke(this.state.modalNoteShop , this.state.note).then((value: any) => {
                                        this.state.modalNoteShop?.updateNote(this.state.note);
                                        this.setState({
                                            modalNotesOpened: false,
                                            note: ""
                                        });                                        
                                    });
                                }
                            }}  primary={true}>
                                Guardar
                            </Button>
                        </div>
                    </Modal.Section>
                </Modal>
            </div>
        );
    }

    public get bulkActions(): any[] {
        return [
            {
                content: 'Confirmar',
                onAction: this.onConfirmClick,
            },
            {
                content: 'Cancelar',
                onAction: this.onDeleteClick,
            },
        ];
    }

    public async onDeleteClick() {
        for(var id of this.state.selectedItems){
            let shop: Shop | undefined = this.shopOfId(id);            
            if(shop instanceof Shop){
                this._deleter.invoke(shop).then((value: any) => {
                    this.setState({});
                });
            }
         }         
    }
    public async onConfirmClick() {
        for(var id of this.state.selectedItems){
            let shop: Shop | undefined = this.shopOfId(id);            
            if(shop instanceof Shop){
                this._confirmer.invoke(shop).then((value: any) => {
                    this.setState({});
                });
            }
         }         
    }

    public shopOfId(id: string): Shop | undefined{
        return this.props.shops.find((e: Shop) => e.id === id);
    }
    public addNote(shop: Shop){
        this.setState({
            modalNotesOpened: true,
            modalNoteShop: shop
        })
    }


    public renderItem(row: Shop) {
        return (
            <ResourceItem
                shortcutActions={[
                    {
                        content: "Añadir nota" , 
                        onAction: () => {
                            this.addNote(row);
                        }
                    }
                ]}
                id={row.id}
                onClick={() => {

                }}>                
                <h3>
                <TextStyle variation="strong">{row.name}</TextStyle>            
                
                </h3>
                {row.confirmed == true ? <Badge status="success" progress="complete">Confirmada</Badge> : <Badge status="new" progress="incomplete">No confirmada</Badge>} <span style={{fontSize: "12px"}}>{row.note}</span>
            </ResourceItem>
        );
    }
    
}