import { LineItem } from "../../core/orders/domain/LineItem";
import { Order } from "../../core/orders/domain/Order";
import { Filter } from "./containers/OrdersTable/OrdersTable";
import { TabFilter } from "./TabFilter";


export class OrdersFilterer{
    filter(filter: Filter, order: Order[]): Order[]{          
        let orders = this.filterByCustomer(filter, order);
            orders = this.filterByTracking(filter, orders);
            orders = this.filterByOrder(filter, orders);
            orders = this.filterByTags(filter, orders);
            orders = this.filterByGlobal(filter, orders);
            orders = this.filterByTab(filter, orders);
            orders = this.filterByShop(filter, orders);
            orders = this.filterByArticle(filter, orders);            
            orders = this.filterByDate(filter, orders);            

            if(localStorage.getItem("email")){
              if(localStorage.getItem("email") === "infoskyroas@gmail.com"){                  
                  orders = orders.filter((e: Order) => {
                    if(e.shippingAddress){
                      if(e.shippingAddress.zip){
                        if(!e.shippingAddress.zip.startsWith("35")){
                          return e;
                        }
                      }
                    }
                  });
              }
            }


            

            return orders.sort((a: Order , b: Order) => this.sortByShop(a , b) || this.sortByOrderNum(a , b));
  }




  private sortByShop(orderA: Order, orderB: Order){
    if(orderA.shop < orderB.shop){
      return -1;
    }
    if(orderA.shop > orderB.shop){
      return 1;
    }
    return 0;
  }

  private sortByOrderNum(orderA: Order, orderB: Order){
    if(orderA.orderNum < orderB.orderNum) { return -1; }
    if(orderA.orderNum > orderB.orderNum) { return 1; }
    return 0;
  }


  private filterByCustomer(filter: Filter, items: Order[]){    
    let value         = filter.customer;    
    let searchValue   = value.trim().toLowerCase();    
    let filteredItems = items.filter((value: Order) => this.getCustomerFullName(value).toLowerCase().indexOf(searchValue) !== -1);    
    return filteredItems;
  } 

  private getCustomerFullName(order: Order){
    return order.shippingAddress?.name + "";
  }

  private filterByTracking(filter: Filter, items: Order[]){
    let value         = filter.tracking;
    let searchValue   = value.trim().toLowerCase();
    if(searchValue.length == 0){
      return items;
    }
    let filteredItems = [];
    for(let order of items){
      for(let fulfillment of order.fullfillments){
        if(fulfillment !== null){
          if(fulfillment["tracking_number"] !== null){
            if(fulfillment["tracking_number"].indexOf(searchValue) !== -1){
              filteredItems.push(order);
              continue;
            }
          }
        }
      }
    }
    return filteredItems;
  }

  private filterByOrder(filter: Filter, items: Order[]){
    let value = filter.orderName;
    let searchValue = value.trim().toLowerCase();
    let filteredItems = items.filter((value: Order) => value.name.indexOf(searchValue) !== -1);
    return filteredItems;
  }

  private filterByTags(filter: Filter, items: Order[]){
    let value = filter.tags;
    let searchValue = value.trim().toLowerCase();
    let filteredItems = items.filter((value: Order) => value.tags.indexOf(searchValue) !== -1);
    return filteredItems;
  }

  private filterByArticle(filter: Filter, items: Order[]){
    let value = filter.article;
    let searchValue = value.trim().toLowerCase();
    let filteredItems = items.filter((value: Order) => value.lineItems.filter( (e: LineItem) => e.title.toLowerCase().indexOf(searchValue) !== -1 ).length > 0);
    return filteredItems;
  }


  private filterByGlobal(filter: Filter, items: Order[]) {
    let value = filter.global;
    if(typeof value === "undefined" || value == null || !value){
      return items;
    }
    let splitted = value.split(',');
    let filteredItems: Order[] = [];
      for(var search of splitted){
          let searchValue   = search.trim().toLowerCase();
          if(searchValue.length == 0){
            continue;
          }
          var f = items.filter((value: Order) => value.parseToString().indexOf(searchValue) !== -1);
          filteredItems = [...filteredItems, ...f];
      }
    return filteredItems;
  }

  private filterByTab(filter: Filter, items: Order[]) {
    let selectedTab = filter.tab;
    let filteredItems = items;
    if (selectedTab == TabFilter.all) {
      filteredItems = items;
    }    
    if(selectedTab == TabFilter.cancelled){
      filteredItems = items.filter((e: Order) => e.isCancelled);
    }
    if(selectedTab == TabFilter.opened){
      filteredItems = items.filter((e: Order) => !e.isCancelled);
    }
    if(selectedTab == TabFilter.notpayed){
      filteredItems = items.filter((e: Order) => e.notPayed);
    } 
    if(selectedTab == TabFilter.withtracking){
      filteredItems = items.filter((e: Order) => e.hasTracking);
    }
    if(selectedTab == TabFilter.notracking){
      filteredItems = items.filter((e: Order) => !e.hasTracking);
    }
    return filteredItems;
  }

  private filterByDate(filter: Filter, items: Order[]) {
    let start = filter.date.start;
    let end   = filter.date.end;
    let filteredItems = items.filter((e: Order) => {
      if(typeof e === "undefined" || e == null || !e){
        return null;
      }      
      let timestamp = Date.parse(e.createdAt);
      let date = new Date();
      date.setTime(timestamp);
      if (date.getTime() >= start.getTime() && date.getTime() <= end.getTime()) {
        return e;
      }
      return null;
    });
    return filteredItems;
  }

  private filterByShop(filter: Filter, items: Order[]) {
    let shop = filter.shop;    
    if (shop === "") {
      return items;
    } else {
      let filteredItems = items.filter((e: Order) => e.shop.replace(".myshopify.com", "") === shop.replace(".myshopify.com", ""));
      return filteredItems;
    }
  }
}