import { AdminRepository } from "../../domain/AdminRepository";

export class AdminRegistrar{
    private _repository: AdminRepository;
    constructor(repository: AdminRepository){
        this._repository = repository;
    }
    invoke(email: string, password: string){ 
        return this._repository.register(email, password);
    }
} 