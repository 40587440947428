import React, { useCallback, useState } from 'react';


export interface AuthState{
    email: string;
    password: string;
    error: Error;
    logging: boolean;
}

export enum Error{
    INVALID_CREDENTIALS, SERVER_ERROR, NO_ERROR
}