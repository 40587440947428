import { Select } from '@shopify/polaris';
import React, { useCallback, useState } from 'react';
import TransportsApi from '../../data/transport/transport_api';

export interface Transport{
    name: string;
    defaultUrl: string;
}

interface TransportSelectorProps{
    onChange(transport: any): void;
}

interface TransportSelectorState{
    transport: Transport[];
    selectedTransport?: Transport;
}

class TransportSelector extends React.Component<TransportSelectorProps, TransportSelectorState>{    
    private _api: TransportsApi = new TransportsApi();
    constructor(props: any){
        super(props);          
        this.state = {
            transport: [],
            selectedTransport: {name: "Selecciona un transporte", defaultUrl: ""}, 
        }
        this.handleSelectionChange = this.handleSelectionChange.bind(this);
    }
    public async componentDidMount(){
        this.setState({
            transport: await this._getTransports()
        })
    }
    private async _getTransports(): Promise<Transport[]>{
        let transport = await this._api.getTransports();
        let result: any[] = [];
        // result.push({
        //     defaultUrl: "",
        //     name: "Selecciona un transporte" , 
        // });
        if(transport.status == 200){
            let json = await transport.json();            
            Object.values(json).forEach((e: any) => {
                result.push({
                    defaultUrl: e["default_url"],
                    name: e["name"]
                });
            });
    
            return result;
        }
        return [];
    }    
    public get options() : any[] {
        return this.state.transport.map((e: Transport) => {
            return {
                label: e.name , 
                value: e.name
            }
        })
    }

    public handleSelectionChange($event: any){
        let transport = this._transportOfName($event);
        this.setState({
            selectedTransport: transport
        });        
        this.props.onChange(transport);
    }

    private _transportOfName(name: string){
        return this.state.transport.filter((e: Transport) => e.name === name)[0];
    }

    render(){
        return (
            <Select label="" value={this.state.selectedTransport?.name} options={this.options} onChange={this.handleSelectionChange}/>
        );
    }
}       

export default TransportSelector;