import { Frame, Spinner, TopBar } from '@shopify/polaris';
import React, { useCallback, useState } from 'react';
import TransportTable from '../../components/transport-table/Transport-table';
import { AllShopFinder } from '../../core/shops/application/find/AllShopFinder';
import { ShopValidChecker } from '../../core/shops/application/valid/ShopValidChecker';
import { Shop } from '../../core/shops/domain/Shop';
import { ShopRepository } from '../../core/shops/domain/ShopRepository';
import { ApiShopRepository } from '../../core/shops/infrastructure/api/ApiShopRepository';
import { Orders } from '../Orders/Orders';
import { Reports } from '../Reports/Reports';
import { Shops } from '../Shops/Shops';
import { Drawer } from './containers/Drawer/Drawer';
import { MainProps } from './MainProps';
import { MainState, Pages } from './MainState';

export class Main extends React.Component<MainProps, MainState> { 
    private _repository: ShopRepository = new ApiShopRepository();
    private _finder: AllShopFinder;
    private _checker: ShopValidChecker;
    constructor(props: any){
        super(props);
        this.state = {
            page: Pages.LOADING,
            shops: []
        }
        this._finder  = new AllShopFinder(this._repository);
        this._checker = new ShopValidChecker(this._repository);
        this.onAdded = this.onAdded.bind(this);
        
    }

    componentDidMount(){
        this._finder.invoke().then((shops: Shop[]) => {      
            var i = 0;      
            this.setState({
                page: Pages.ORDERS, 
                shops: shops
            })
            // shops.forEach((shop: Shop, index: number, array: Shop[]) => {
            //     this._checker.invoke(shop).then((valid: any) => {
            //         i++;
            //         if(valid == true){
            //             this.state.shops.push(shop);
            //             this.setState({
            //                 shops: this.state.shops
            //             });
            //         }   
            //         if(i == array.length){
                        
            //         }                 
            //     });
            // });       
        });
    }
    

    render() {
        return (
            <Frame 
            topBar={
                <TopBar showNavigationToggle>                    
                </TopBar>
            } 
            navigation={
                <Drawer onChange={(page: Pages) => this.setState({page: page})}>                    
                </Drawer>
            }>
                {this.renderContent}
            </Frame>
        );
    }
    sleep(ms: number) {
        return new Promise(resolve => setTimeout(resolve, ms));
      }
    public async onAdded(){
        var shops: Shop[] = await this._finder.invoke();
        var i = 0;      
        for(var shop of shops){
            var valid = await this._checker.invoke(shop);
            await this.sleep(200);
            i++;
            if(valid == true){
                this.state.shops.push(shop);
                this.setState({
                    shops: this.state.shops
                });
            }   
            if(i == shops.length){
                this.setState({
                    page: Pages.SHOPS
                })
            }   
        }
    }

    public get renderContent(): any{
        switch(this.state.page){
            case Pages.ORDERS: 
                return ( <Orders shops={this.state.shops}></Orders> );
            break;
            case Pages.SHOPS: 
                return ( <Shops onAdded={this.onAdded} shops={this.state.shops}></Shops> );
            break;
            case Pages.TRANSPORTATION: 
                return ( <TransportTable></TransportTable> );
            break;
            case Pages.LOADING: 
                return <div style={{margin: "0 auto", width: "100%", display: "flex", justifyContent: "center"}}><Spinner size="large"></Spinner></div>;
            break;
        }
    }

}