import { Shop } from "./Shop";

export abstract class ShopRepository {
    abstract all(): Promise<Shop[]>;
    abstract confirmed(): Promise<Shop[]>;
    abstract valid(shop: Shop): Promise<any>;
    abstract confirm(shop: Shop): Promise<any>;
    abstract addNote(shop: Shop, note: string): Promise<any>;
    abstract privateInstall(shopName: string, apiKey: string, secret: any): Promise<any>;
    abstract delete(shop: Shop): Promise<any>;
}