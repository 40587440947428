import React from 'react';
import logo from './logo.svg';
import './App.css';
import '@shopify/polaris/dist/styles.css';
import esTranslations from '@shopify/polaris/locales/es.json';
import {AppProvider, Page, Card, Button, Navigation} from '@shopify/polaris';
import OrdersTabs from './components/orders-tabs/Orders-tabs';
import {HomeMajor, OrdersMajor, ProductsMajor} from '@shopify/polaris-icons';
import RegisterForm from './components/register-form/Register-form';
import LoginForm from './components/login-form/Login-form';
import { BrowserRouter, Switch, Route, Link } from 'react-router-dom';
import RegisterPage from './pages/register/Register-page';
import AuthPage from './pages/auth/Auth-page';
import MainPage from './pages/main/Main-page';
import OrdersTable from './components/orders-table/Orders-table';
import TokenApi from './data/token/token_api';
import { RouteComponentProps, withRouter } from "react-router-dom";
import { GuardProvider, GuardedRoute } from 'react-router-guards';
import { buildThemeContext } from '@shopify/polaris/dist/types/latest/src/utilities/theme';
import { Orders } from './containers/Orders/Orders';
import Register from './containers/Register/Register';
import Start from './containers/Start/Start';
import { Main } from './containers/Main/Main';
import { Reports } from './containers/Reports/Reports';
import { Stock } from './containers/Stock/Stock';


class App extends React.Component { 
  private _tokenApi: TokenApi = new TokenApi();
  private _url: string = "https://api.multistorefy.appsbealion.com/api/admin/check-token"
  constructor(props: any){
    super(props);
    this.validTokenGuard = this.validTokenGuard.bind(this);
  }

  async componentDidMount(){
    
  }

  async validTokenGuard(to: any, from: any, next: any){    
    if(to.meta["auth"] == false){
      next();
      return;
    }
    let tokenResponse = await this._tokenApi.checkToken();
    if(tokenResponse.status !== 200){
      next.redirect("/start");
    }else{
      next();
    }
  }

  render(){
    return (
      <AppProvider i18n={esTranslations}>
        <BrowserRouter>
          <GuardProvider guards={[this.validTokenGuard]}>
            <Switch>
              <GuardedRoute path="/start" exact component={Start} meta={{ auth: false }}></GuardedRoute>
              <GuardedRoute path="/contabilidadskyroas" exact component={Reports} meta={{auth: true}}></GuardedRoute> 
              <GuardedRoute path="/stockskyroas" exact component={Stock} meta={{auth: true}}></GuardedRoute> 
              <GuardedRoute path='/' component={Main} meta={{auth: true}} />                   
            </Switch>
          </GuardProvider>
        </BrowserRouter>
      </AppProvider>
    )
  }
}

export default App;
