import { Badge } from '@shopify/polaris';
import React, { useCallback, useState } from 'react';
import { v1 } from 'uuid';
import { OrderTagsProps } from './OrderTagsProps';
import { OrderTagsState } from './OrderTagsState';

export class OrderTags extends React.Component<OrderTagsProps, OrderTagsState> {
    render() {
        return ( this.tags.map((e: string) => <Badge key={v1()} status="info">{e}</Badge>) );
    }

    
    public get tags() : string[] {
        return this.props.tags.split(" ").filter((e: string) => e.length > 0);
    }
    
}