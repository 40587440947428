import React, { useCallback, useState } from 'react';



class ApiKeyApi{
    private _url: string = "https://api.multistorefy.appsbealion.com/api/admin/apikey"
    public getApiKey(){                
        return fetch(this._url, {
            headers: {
                'X-Token': localStorage.getItem("token") ?? ""
            }
        });
    }
}

export default ApiKeyApi;