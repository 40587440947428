export class Admin {
    private _email: string;
    private _token: string;
    constructor(email: string, token: string){
        this._email = email;
        this._token = token;
    }
    
    public get email() : string {
        return this._email;
    }
    
    public get token() : string {
        return this._token;
    }
    
}