import React, { useCallback, useState } from 'react';



class TransportApi{
    private _url: string = "https://api.multistorefy.appsbealion.com/api/admin/transport"
    public getTransports(){                
        return fetch(this._url, {
            headers: {
                'X-Token': localStorage.getItem("token") ?? ""
            }
        });
    }
    public addTransport(id: string, name: string, defaultUrl: string){
        let formData: FormData = new FormData();
        formData.append("id", id);
        formData.append("name", name);
        formData.append("default_url" , defaultUrl);
        return fetch(this._url, {
            body: formData,
            method: "POST",
            headers: {
                'X-Token': localStorage.getItem("token") ?? ""
            }
        });
    }

    public deleteTransport(id: string){
        return fetch(this._url + "/" + id, {            
            method: "DELETE",
            headers: {
                'X-Token': localStorage.getItem("token") ?? ""
            }
        });
    }
    
}

export default TransportApi;