import { ActionList, Button, Popover } from '@shopify/polaris';
import React, { useCallback, useState } from 'react';
import { OrderCanceler } from '../../../../../../core/orders/application/cancel/OrderCanceler';
import { Order } from '../../../../../../core/orders/domain/Order';
import { OrderRepository } from '../../../../../../core/orders/domain/OrderRepository';
import { ApiOrderRepository } from '../../../../../../core/orders/infrastructure/api/ApiOrderRepository';
import OrdersApi from '../../../../../../data/orders/orders_api';
import { OrderActionsPopoverProps } from './OrderActionsPopoverProps';
import { OrderActionsPopoverState } from './OrderActionsPopoverState';


export class OrderActionsPopover extends React.Component<OrderActionsPopoverProps, OrderActionsPopoverState> { 
    private _api: OrdersApi = new OrdersApi();
    private _repository: OrderRepository = new ApiOrderRepository();
    private _canceler: OrderCanceler;
    constructor(props: any){
        super(props);
        this.state = {
            opened: false
        }
        this.toggleOpened = this.toggleOpened.bind(this);
        this._canceler = new OrderCanceler(this._repository);
    }

    public get activator() : any {
        return (
            <Button onClick={this.toggleOpened} disclosure disabled={this.props.selectedCount == 0}>Mas acciones</Button>
        );
    }

    public toggleOpened(){
        this.setState({
            opened: !this.state.opened
        })
    }
    

    render() {
        return (
            <div>
            <Popover active={this.state.opened} activator={this.activator} onClose={() => {this.setState({opened: false})}} >
                <ActionList items={this.actionItems} />
            </Popover>
            </div>
        );
    }

    
    public get actionItems() : any[] {
        var items = [
            {
                content: 'Exportar a CSV', onAction: async () => {
                    let selectedOrders = this.props.ordersSelected;
                    let rawOrders = selectedOrders.map((e: Order) => e.raw);
                        this._api.exportOrders(rawOrders).then((response: Response) => {
                            return response.blob();
                        })
                        .then((blob: any) => {
                        var url = window.URL.createObjectURL(blob);
                        var a = document.createElement('a');
                        a.href = url;
                        a.download = "export.csv";
                        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                        a.click();
                        a.remove();  //afterwards we remove the element again         
                        });
                    }
                },
            {
                content: 'Cancelar pedido/os', onAction: async () => {
                    if(window.confirm("Cancelar pedidos ?")){
                        this.props.ordersSelected.forEach((value: Order) => {
                            this._canceler.invoke(value).then((value: any) => {

                            });
                        })
                    }
                }
            },
        ];  
        if(localStorage.getItem("email") === "infoskyroas@gmail.com"){
            items.push({
                content: 'Exportar para GLS', onAction: async () => {
                    let selectedOrders = this.props.ordersSelected;
                    let rawOrders = selectedOrders.map((e: Order) => e.raw);
                        this._api.exportGLS(rawOrders).then((response: Response) => {
                            return response.blob();
                        })
                        .then((blob: any) => {
                            var url = window.URL.createObjectURL(blob);
                            var a = document.createElement('a');
                            a.href = url;
                            a.download = "export.csv";
                            document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                            a.click();
                            a.remove();  //afterwards we remove the element again         
                        });
                    }
            });
        }
        return items;
    }
    

}