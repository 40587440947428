import { Shop } from "../../../shops/domain/Shop";
import { OrderRepository } from "../../domain/OrderRepository";

export class ShopOrderOfIdFinder{
    private _repository: OrderRepository;
    constructor(repository: OrderRepository){
        this._repository = repository;
    }
    invoke(id: string, shop: Shop){
        return this._repository.ofId(id, shop);
    }
}