import { Badge } from '@shopify/polaris';
import React, { useCallback, useState } from 'react';
import { OrderPaidBadgeProps } from './OrderPaidBadgeProps';
import { OrderPaidBadgeState } from './OrderPaidBadgeState';

export class OrderPaidBadge extends React.Component<OrderPaidBadgeProps, OrderPaidBadgeState>{
    render() {
        if (this.props.financial == "paid") {
            return (
              <Badge progress="complete">Pagado</Badge>
            )
          }
          if (this.props.financial == "pending") {
            return (
              <Badge status="warning" progress="incomplete">Pago pendiente</Badge>
            )
          }
          return "";
    }
}