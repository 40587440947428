import { ProductRepository } from "../../domain/ProductRepository";

export class ProductLister{
    private _repository: ProductRepository;
    constructor(repository: ProductRepository){
        this._repository = repository;
    }
    invoke(){
        return this._repository.getRawProducts();
    }
}