import { Shop } from "../../../shops/domain/Shop";
import { ProductRepository } from "../../domain/ProductRepository";

export class ImageProductFinder{ 
    private _repository: ProductRepository;
    constructor(repository: ProductRepository){
        this._repository = repository;
    }
    invoke(shop: Shop, productId: string){
        return this._repository.imageOfProduct(shop, productId);
    }
}