import { Button, Card, Checkbox, ColumnContentType, DataTable, IndexTable, Pagination, SortDirection } from '@shopify/polaris';
import React, { useCallback, useState } from 'react';
import { v1 } from 'uuid';
import FieldPopover from '../../../../components/orders-table/components/Field-popover';
import { ShopOrdersFinder } from '../../../../core/orders/application/find/ShopOrdersFinder';
import { Order } from '../../../../core/orders/domain/Order';
import { OrderRepository } from '../../../../core/orders/domain/OrderRepository';
import { ApiOrderRepository } from '../../../../core/orders/infrastructure/api/ApiOrderRepository';
import { Shop } from '../../../../core/shops/domain/Shop';
import { PopoverArticles } from '../../../Shared/PopoverArticles/PopoverArticles';
import { OrdersFilterer } from '../../OrdersFilterer';
import { TabFilter } from '../../TabFilter';
import { OrderPaidBadge } from './components/OrderPaidBadge/OrderPaidBadge';
import { OrderTags } from './components/OrderTags/OrderTags';
import { OrderActionsPopover } from './containers/OrderActions/OrderActionsPopover';
import { OrdersPaginator } from './containers/OrdersPaginator/OrdersPaginator';
import { OrderTracking } from './containers/OrderTracking/OrderTracking';
import { OrdersTableProps } from './OrdersTableProps';
import { OrdersTableState } from './OrdersTableState';
import './OrdersTable.css';
import { OrdersState } from '../../OrdersState';
import { OrdersProps } from '../../OrdersProps';

export class OrdersTable extends React.Component<OrdersTableProps, OrdersTableState> { 
    private _repository: OrderRepository = new ApiOrderRepository();
    private _finder: ShopOrdersFinder;
    private _filterer: OrdersFilterer = new OrdersFilterer();
    constructor(props: any){
        super(props);
        this.state = {
            orders: [],
            page: 1, 
            filter: this.props.filter
        }
        this._finder    = new ShopOrdersFinder(this._repository);
        this.changePage = this.changePage.bind(this);
        this.onSort = this.onSort.bind(this);
    }
    sleep(ms: number) {
        return new Promise(resolve => setTimeout(resolve, ms));
      }
    public async componentDidMount(){     
        for(let shop of this.props.shops){
            await this.loadOrders(shop, false);
            await this.sleep(500);
        }
    }
    private async loadOrders(shop: Shop, sinceId: Order | boolean){
        var value: [Order[], Order | boolean] = await this._finder.invoke(shop, sinceId);
        let orders    = value[0];
        let lastOrder = value[1];
        this.setState({ orders: this.state.orders.concat(...orders) });
        if(lastOrder !== false){                       
                await this.sleep(500);     
                await this.loadOrders(shop, lastOrder);            
        }        
    }

  
    componentWillReceiveProps(nextProps: OrdersTableProps){
        if( nextProps.filter.shop !== this.props.filter.shop || 
            nextProps.filter.global !== this.props.filter.global || 
            nextProps.filter.date !== this.props.filter.date ){
                this.setState({
                    filter: nextProps.filter
                }, () => {
                    this.props.onFilterChange(this.state.filter);
                });
        }
    }

    public dateOfOrder(order: Order){
        let timestamp = Date.parse(order.createdAt);
        let date      = new Date();
        date.setTime(timestamp);
        return Intl.DateTimeFormat('es-ES', {day: "2-digit", month: "2-digit", year: "numeric"}).format(date);
    }

    public shopOfName(name: string): Shop{
        return this.props.shops.find((e: Shop) => e.name === name) ?? new Shop(v1() , "" , false, "");
    }

    private paginated(): Order[]{
        let page     = this.state.page
        let per_page = 10;
        let offset   = (page - 1) * per_page;
        let items    = this._filterer.filter(this.props.filter,this.state.orders).slice(offset).slice(0, per_page);
        return items;
    }

    private filtered(): Order[]{
        return [];
    }

    private changePage(page: number){
        this.setState({
            page: page
        });
    }
    
    public get rows(): any{
        return this.paginated().map((order: Order) => [
            <Checkbox label="" checked={order.selected} onChange={(newValue: boolean) => {
                order.toggleSelect(newValue);
                this.setState({});
            }}/>, 
            <PopoverArticles shop={this.shopOfName(order.shop)} key={order.id} lineItems={order.lineItems}></PopoverArticles>,
            <b className={order.isCancelled == true ? "canceled" : ""}>{order.name}</b>,
            <OrderTags tags={order.tags}></OrderTags>,
            <b className={order.isCancelled == true ? "canceled" : ""}>{order.shop}</b>,
            <b className={order.isCancelled == true ? "canceled" : ""}>{order.dateFormatted}</b>,
            <b className={order.isCancelled == true ? "canceled" : ""}>{order.shippingAddress?.fullName}</b>,
            <b className={order.isCancelled == true ? "canceled" : ""}>{order.shippingAddress?.fullAddress}</b>,
            <OrderPaidBadge financial={order.financialStatus}></OrderPaidBadge>,
            <OrderTracking orderUpdated={(newOrder: Order) => {
                let oldOrder      = this.state.orders.filter((e: any) => e.id == newOrder.id)[0];
                let index         = this.state.orders.indexOf(oldOrder);
                let updatedOrders = this.state.orders;
                if(index !== -1){          
                    updatedOrders[index] = newOrder;          
                }
                this.setState({
                    orders: updatedOrders
                })
            }} order={order} shop={this.shopOfName(order.shop)}></OrderTracking>
        ]);
    }

    public resetPage(){
        this.setState({
            page: 1
        });
    }

    public get sortables() : boolean[] { return [false, false, false, false, false, true, false,false,false,false,false]; }
    
    public get columnContentTypes() : ColumnContentType[] { return ['text', 'text', 'text', 'text', 'text', 'text', 'text', 'text', 'numeric', 'text', 'text'];}
    
    public get headings(): any{
        return [
            <Checkbox label={this.selectedCount} checked={this.allChecked}  onChange={(newValue: boolean) => {this.toggleSelectAll(newValue)}} />, 
            <FieldPopover title="Artículos" onFilter={(value: string) => {
                this.setState({
                    page: 1,
                    filter: {
                        article: value, 
                        address: this.state.filter.address, 
                        customer: this.state.filter.customer, 
                        orderName: this.state.filter.orderName, 
                        shop: this.state.filter.shop, 
                        tags: this.state.filter.tags,
                        tracking: this.state.filter.tracking,
                        global: this.state.filter.global,
                        tab: this.state.filter.tab,
                        date: {
                            start: this.state.filter.date.start,
                            end:  this.state.filter.date.end,
                          },
                    }
                }, () => {
                    this.props.onFilterChange(this.state.filter);
                })
            }} ></FieldPopover> , 
            <FieldPopover title="Pedido" onFilter={(value: string) => {
                this.setState({
                    page: 1,
                    filter: {
                        article: this.state.filter.article, 
                        address: this.state.filter.address, 
                        customer: this.state.filter.customer, 
                        orderName: value,
                        shop: this.state.filter.shop, 
                        tags: this.state.filter.tags,
                        tracking: this.state.filter.tracking,
                        global: this.state.filter.global,
                        tab: this.state.filter.tab,
                        date: {
                            start: this.state.filter.date.start,
                            end:  this.state.filter.date.end,
                          },
                    }
                }, () => {
                    this.props.onFilterChange(this.state.filter);
                })
            }} ></FieldPopover> , 
            <FieldPopover title="Etiquetas" onFilter={(value: string) => {
                this.setState({
                    page: 1,
                    filter: {
                        article: this.state.filter.article, 
                        address: this.state.filter.address, 
                        customer: this.state.filter.customer, 
                        orderName: this.state.filter.orderName,
                        shop: this.state.filter.shop, 
                        tags: value,
                        tracking: this.state.filter.tracking,
                        global: this.state.filter.global,
                        tab: this.state.filter.tab,
                        date: {
                            start: this.state.filter.date.start,
                            end:  this.state.filter.date.end,
                          },
                    }
                }, () => {
                    this.props.onFilterChange(this.state.filter);
                })
            }} ></FieldPopover> , 
            <FieldPopover title="Tienda" onFilter={(value: string) => {
                this.setState({
                    page: 1,
                    filter: {
                        article: this.state.filter.article, 
                        address: this.state.filter.address, 
                        customer: this.state.filter.customer, 
                        orderName: this.state.filter.orderName,
                        shop: value, 
                        tags: this.state.filter.tags,
                        tracking: this.state.filter.tracking,
                        global: this.state.filter.global,
                        tab: this.state.filter.tab,
                        date: {
                            start: this.state.filter.date.start,
                            end:  this.state.filter.date.end,
                          },
                    }
                }, () => {
                    this.props.onFilterChange(this.state.filter);
                })
            }} ></FieldPopover> ,
            "Fecha" , 
            <FieldPopover title="Cliente" onFilter={(value: string) => {
                this.setState({
                    page: 1,
                    filter: {
                        article: this.state.filter.article, 
                        address: this.state.filter.address, 
                        customer: value, 
                        orderName: this.state.filter.orderName,
                        shop: this.state.filter.shop, 
                        tags: this.state.filter.tags,
                        tracking: this.state.filter.tracking,
                        global: this.state.filter.global,
                        tab: this.state.filter.tab,
                        date: {
                            start: this.state.filter.date.start,
                            end:  this.state.filter.date.end,
                          },
                    }
                }, () => {
                    this.props.onFilterChange(this.state.filter);
                })
            }}  ></FieldPopover> ,                   
            <FieldPopover title="Dirección" onFilter={(value: string) => {
                this.setState({
                    page: 1,
                    filter: {
                        article: this.state.filter.article, 
                        address: value, 
                        customer: this.state.filter.customer, 
                        orderName: this.state.filter.orderName,
                        shop: this.state.filter.shop, 
                        tags: this.state.filter.tags,
                        tracking: this.state.filter.tracking,
                        global: this.state.filter.global,
                        tab: this.state.filter.tab,
                        date: {
                            start: this.state.filter.date.start,
                            end:  this.state.filter.date.end,
                          },
                    }
                }, () => {
                    this.props.onFilterChange(this.state.filter);
                })
            }} ></FieldPopover> ,                       
            "Pago" ,                   
            <FieldPopover title="Tracking" onFilter={(value: string) => {
                this.setState({
                    page: 1,
                    filter: {
                        article: this.state.filter.article, 
                        address: this.state.filter.address, 
                        customer: this.state.filter.customer, 
                        orderName: this.state.filter.orderName,
                        shop: this.state.filter.shop, 
                        tags: this.state.filter.tags,
                        tracking: value,
                        global: this.state.filter.global,
                        tab: this.state.filter.tab,
                        date: {
                            start: this.state.filter.date.start,
                            end:  this.state.filter.date.end,
                          },
                    }
                }, () => {
                    this.props.onFilterChange(this.state.filter);
                })
            }} ></FieldPopover> ,                   
        ];
    }

    public toggleSelectAll(selected: boolean){
        this._filterer.filter(this.props.filter,this.state.orders).map((order: Order) => order.toggleSelect(selected));
        this.setState({});
    }

    
    public get allChecked() : boolean | 'indeterminate' | undefined {
        return this.selectedCount == this._filterer.filter(this.props.filter,this.state.orders).length ? true : (this.selectedCount == 0 ? false : 'indeterminate');
    }
    
    
    public get selectedCount() : number {
        return this.ordersSelected.length;
    }
    public get ordersSelected(): Order[]{
        return this._filterer.filter(this.props.filter,this.state.orders).filter((e: Order) => e.selected == true);
    }
    

    public render() {
        return (
             <div style={{margin:"8px"}}>      
                   <OrderActionsPopover ordersSelected={this.ordersSelected} selectedCount={this.selectedCount}></OrderActionsPopover>
                   <DataTable onSort={this.onSort} rows={this.rows} headings={this.headings} sortable={this.sortables} columnContentTypes={this.columnContentTypes} />                   
                   <OrdersPaginator onPageChange={this.changePage} orders={this._filterer.filter(this.props.filter,this.state.orders)}></OrdersPaginator>                
             </div>
        );
    }

    public onSort(index: any, direction: SortDirection){
        let sortedItems = this.state.orders.sort((a: Order, b: Order) => {             
            let aTimestamp = Date.parse(a.createdAt);
            let aDate = new Date();
            aDate.setTime(aTimestamp);
            let bTimestamp = Date.parse(b.createdAt);
            let bDate = new Date();
            bDate.setTime(bTimestamp);
            if(direction === 'descending'){
              return bDate.getTime() - aDate.getTime();  
            }
            return aDate.getTime() - bDate.getTime();
        });  
        this.setState({
            orders: sortedItems
        });

    }

}




export interface Filter{
    global: string;
    shop: string;
    article: string;
    orderName: string;
    tags: string;
    customer: string;
    address: string;
    tracking: string;
    tab: TabFilter;
    date: {
        start: Date , 
        end: Date
    }
}

