import { Button, Card } from '@shopify/polaris';
import React, { useCallback, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Logo } from '../../components/Logo/Logo';
import { Auth } from '../Auth/Auth';
import Register from '../Register/Register';
import './Start.css';
import { StartProps } from './StartProps';
import { StartState } from './StartState';
class Start extends React.Component<RouteComponentProps, StartState>{
    constructor(props: any){
        super(props);
        this.state = {
            onLogin: true, 
            onRegister: false
        }
        this.toggleRegisterLogin = this.toggleRegisterLogin.bind(this);
    }
    render() {
        return (
            <div className="main-container">                
            <div className="left-side">
                <Logo></Logo>
                <div style={{margin:"16px"}}>                        
                    {this.form}
                </div>
            </div>
            <div className="right-side">
                <div className="backgrounded-text">
                    <span>Gestiona tus tiendas desde un único sitio</span>
                    <Button onClick={() => {
                        window.open("https://apps.shopify.com/multistorefy" , "_blank");
                    }} primary={false}>Ir a la tienda de apps</Button>
                </div>                
            </div>
        </div>
        );
    }

    public toggleRegisterLogin(){
        this.setState({
            onRegister: !this.state.onRegister, onLogin: !this.state.onLogin });
    }
    
    public get form() : any {
        if(this.state.onLogin === true){
            return ( <Auth onSuccessAuth={() => {
                this.props.history.push("/main");
            }} onRegister={this.toggleRegisterLogin}></Auth> );
        }
        return (
            <Register onLogin={this.toggleRegisterLogin}></Register>
        );
    }
}

export default withRouter(Start);

