import { Button, Card, FormLayout, Range, TextField } from '@shopify/polaris';
import React, { useCallback, useState } from 'react';
import { v1 } from 'uuid';
import { Shop } from '../../../../core/shops/domain/Shop';
import { DatePopover } from '../../../Shared/DatePopover/DatePopover';
import { ShopSelector } from '../../../Shared/ShopSelector/ShopSelector';
import './FiltersForm.css';
import { FiltersFormProps } from './FiltersFormProps';
import { FiltersFormState } from './FiltersFormState';

export class FiltersForm extends React.Component<FiltersFormProps, FiltersFormState>{
    constructor(props: any){
        super(props);
        this.state = {
            filter: this.props.filter,
            dateRangeOpened: false
        }
    }

    componentDidUpdate(){
       
    }

    render() {
        return (
            <div style={{margin: "8px"}}>
             <Card>
                 <div className="filters-form">
                    <FormLayout>
                        <FormLayout.Group condensed>
                            <TextField label="Buscar" value={this.state.filter.global} onChange={(value: string) => {
                                this.setState({
                                    filter: {
                                        global: value , 
                                        article: this.state.filter.article, 
                                        address: this.state.filter.address, 
                                        customer: this.state.filter.customer, 
                                        orderName: this.state.filter.orderName, 
                                        shop: this.state.filter.shop, 
                                        tags: this.state.filter.tags,
                                        tracking: this.state.filter.tracking,
                                        date: {
                                            start: this.state.filter.date.start,
                                            end:  this.state.filter.date.end,
                                            },
                                        tab: this.state.filter.tab
                                    }
                                }, () => {
                                    this.props.onFilterChange(this.state.filter);
                                });                                
                            }}></TextField>
                            <ShopSelector onShopSelected={(shop: Shop | undefined) => {                                
                                      let shopName = shop instanceof Shop ? shop.name: "";
                                      this.setState({
                                        filter: {
                                            shop: shopName , 
                                            article: this.state.filter.article, 
                                            address: this.state.filter.address, 
                                            customer: this.state.filter.customer, 
                                            orderName: this.state.filter.orderName, 
                                            global: this.state.filter.global, 
                                            tags: this.state.filter.tags,
                                            tracking: this.state.filter.tracking,
                                            date: {
                                                start: this.state.filter.date.start,
                                                end:  this.state.filter.date.end,
                                              },
                                            tab: this.state.filter.tab
                                        }
                                    }, () => {
                                        this.props.onFilterChange(this.state.filter)
                                    });
                            }} shops={this.props.shops}></ShopSelector>                            
                            <DatePopover
                             range={this.state.filter.date}
                             onOpened={(value: boolean) => this.setState({
                                dateRangeOpened: value
                            })} opened={this.state.dateRangeOpened} onDateChange={(range: Range) => {
                                this.state.filter.date = {
                                    start: range.start, 
                                    end: range.end
                                };
                                this.props.onFilterChange(this.state.filter);                                      
                            }} key={v1()}></DatePopover>

                        </FormLayout.Group>
      
                    </FormLayout>
                 </div>
             </Card>
             </div>
        );
    }
    debounce(callback: any, wait: any) {
        let timerId: any;
        return (...args: any) => {
          clearTimeout(timerId);
          timerId = setTimeout(() => {
            callback(...args);
          }, wait);
        };
      }
}