import { Button, Card, DatePicker, Popover, RadioButton, Range, Stack } from '@shopify/polaris';
import React, { useCallback, useState } from 'react';
import { v1 } from 'uuid';
import { DatePopoverProps } from './DatePopoverProps';
import { DatePopoverState } from './DatePopoverState';

export class DatePopover extends React.Component<DatePopoverProps, DatePopoverState> {
    private _predefaultDates: any = {
        today: {
          start: new Date(new Date().setHours(0, 0, 0, 0)),
          end: new Date(new Date().setHours(23, 59, 59, 999)),
        },
        lastSevenDays: {
          start: new Date(new Date().setDate(new Date().getDate() - 7)),
          end: new Date()
        },
        lastThirtyDays: {
          start: new Date(new Date().setDate(new Date().getDate() - 30)),
          end: new Date()
        },
        lastNinetyDays: {
          start: new Date(new Date().setDate(new Date().getDate() - 90)),
          end: new Date()
        },
        lastTwelveMonths: {
          start: new Date(new Date().setDate(new Date().getDate() - 365)),
          end: new Date()
        }
      };
    constructor(props: any){
        super(props);
        this.state = {
            active: this.props.opened,
            rangeSelected: {
                start: this.props.range != null ? this.props.range.start :  new Date(new Date().setMonth(0)), 
                end:  this.props.range != null ? this.props.range.end : new Date(new Date().setHours(23, 59, 59, 999)),
            },
            month: new Date().getMonth() , 
            year: new Date().getFullYear()
        }
        
    }

    
    public get activator() : any {
        return (
            <Button onClick={() => {
                this.setState({
                    active: !this.state.active
                }, () => {
                    
                })
            }}>                
                {new Intl.DateTimeFormat('es', {month: "long", year: "numeric", day: "2-digit", hour12: false}).format(this.state.rangeSelected.start)} 
                 - 
                {new Intl.DateTimeFormat('es', {month: "long", year: "numeric", day: "2-digit", hour12: false}).format(this.state.rangeSelected.end)}
            </Button>
        )
    }

    
    public get radioButtons() : RadioDateButton[] {
        return [
            {
                start: new Date(new Date().setHours(0, 0, 0, 0)),
                end: new Date(new Date().setHours(23, 59, 59, 999)),
                label: "Hoy",
                id: RadioDateButtonId.today
            },
            {
                start: new Date(new Date().setDate(new Date().getDate() - 7)),
                end: new Date(),
                label: "Últimos 7 días",
                id: RadioDateButtonId.last7days
            }, 
            {
                start: new Date(new Date().setDate(new Date().getDate() - 30)),
                end: new Date(),
                label: "Últimos 30 días" , 
                id: RadioDateButtonId.last30days
            },
            {
                start: new Date(new Date().setDate(new Date().getDate() - 90)),
                end: new Date(),
                label: "Últimos 90 días",
                id: RadioDateButtonId.last90days
            }, 
            {
                start: new Date(new Date().setDate(new Date().getDate() - 365)),
                end: new Date(),
                label: "Últimos 12 meses",
                id: RadioDateButtonId.last12months
            }
        ]
    }

    
    public get renderRadioButtons() : any {
        return this.radioButtons.map((e: RadioDateButton, index: number) => {
            let id = v1();
            return ( <div key={id} style={{display: "flex", flexDirection: "row", paddingTop: "4px", paddingBottom: "4px", margin: "4px" }}>
                        <input onChange={(value: any) => {
                            let dateButton = this.radioButtons.find((e: RadioDateButton) => e.id == value.currentTarget.value);                            
                            this.setState({
                                rangeSelected: {
                                    start: dateButton?.start ?? new Date(),
                                    end: dateButton?.end ?? new Date()
                                }
                            }, () => {
                                this.props.onDateChange(this.state.rangeSelected);
                            });
                        }} id={e.id.toString()} value={e.id.toString()} name="predefault_date" type="radio"></input>
                        <label htmlFor={id} >{e.label}</label>
                    </div>);
         });
    }
    
    
    

    public render() {
        return (
            <div>
                <div style={{marginBottom: "0.4rem"}}>
                <label>
                    Filtrar por fechas
                </label>
                </div>
             <Popover fullWidth={true} onClose={() => {

             }} active={this.state.active} activator={this.activator}>       
                 <Popover.Pane fixed>
                    <Popover.Section>
                        <Button onClick={() => { this.props.onDateChange(this.state.rangeSelected);}} size={"slim"}> Filtrar</Button>
                    </Popover.Section>
                </Popover.Pane>       
                <Popover.Pane>
                    <div style={{margin: "8px", padding: "8px", position: "relative"}}>
                        <div style={{display: "flex", flexDirection: "column"}}>
                            { this.renderRadioButtons }                            
                        </div>
                        <hr></hr>
                        <DatePicker multiMonth 
                        selected={this.state.rangeSelected}
                        onChange={(range: any) => {                            
                            let r   = range as Range;                                   
                            r.end.setHours(23);
                            r.end.setMinutes(59);
                            r.end.setSeconds(59);
                            r.end.setMilliseconds(999);
                            this.setState({
                                rangeSelected: r
                            });
                        }} 
                        
                        onMonthChange={(month: number, year: number) => {
                            this.setState({
                                month: month, 
                                year: year
                            })
                        }} allowRange={true} month={this.state.month} year={this.state.year} />
                    </div>   
                    </Popover.Pane>              
             </Popover>
             </div>
        );
    }
}

interface RadioDateButton{    
    start: Date;
    end: Date;
    label: string;
    id: RadioDateButtonId;
}

enum RadioDateButtonId{
    "today" , "last7days" , "last30days" , "last90days" , "last12months"
}