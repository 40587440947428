import { Badge, SkeletonBodyText, SkeletonDisplayText, SkeletonThumbnail } from '@shopify/polaris';
import React, { useCallback, useState } from 'react';
import { ImageProductFinder } from '../../../../../core/products/application/image/ImageProductFinder';
import { ProductRepository } from '../../../../../core/products/domain/ProductRepository';
import { ApiProductRepository } from '../../../../../core/products/infrastructure/api/ApiProductRepository';
import { ArticleLineItemProps } from './ArticleLineItemProps';
import { ArticleLineItemState } from './ArticleLineItemState';


export class ArticleLineItem extends React.Component<ArticleLineItemProps, ArticleLineItemState> {
    private _repository: ProductRepository = new ApiProductRepository();
    private _imageFinder: ImageProductFinder;
    constructor(props: any){
        super(props);
        this._imageFinder = new ImageProductFinder(this._repository);
        this.state = {
            image: null,
            loading: true
        }
    }
    
    componentDidMount(){
        this._imageFinder.invoke(this.props.shop, this.props.lineItem.productId.toString()).then((value: any) => {
            this.setState({
                image: value,        
                loading: false        
            });            
        });
    }

    render() {
        return (
            <div style={{display: "flex", justifyContent: "center", alignItems: "center", padding: "1em"}}>                   
                 {this.state.loading == true ? <SkeletonThumbnail size={"small"}/> : <img style={{width: "4rem", height: "4rem", marginRight: "4px"}} src={this.state.image}></img>}
                 <div style={{flex: "1"}}>
                {this.props.lineItem.name} - <Badge  status="success">{this.props.lineItem.price.toString() + " €"}</Badge> <Badge >{"x " + this.props.lineItem.quantity.toString()}</Badge>                
                </div>
            </div>
        );
    }
}