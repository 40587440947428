export class Shop {
    private _id: string;
    private _name: string;
    private _confirmed: boolean;
    private _selected: boolean;
    private _note: string;
    constructor(id: string, name: string, confirmed: boolean, note: string){
        this._id = id;
        this._name = name;
        this._confirmed = confirmed;
        this._selected = false;
        this._note = note;
    }    
    
    public get id() : string {
        return this._id;
    }
    
    public get name() : string {
        return this._name;
    }    
    public get confirmed() : boolean {
        return this._confirmed;
    }        
    public get selected() : boolean {
        return this._selected;
    }
    
    public get note() : string {
        return this._note;
    }

    public updateNote(note: string){
        this._note = note;
    }
    
    public toggleSelect(value: boolean){
        this._selected = value;
    }

    public changeConfirmed(value: boolean){
        this._confirmed = value;
    }
    
}