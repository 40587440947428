import React from "react";
import { ProductLister } from "../../core/products/application/list/ProductLister";
import { ProductStockUpdater } from "../../core/products/application/stock/product_stock_updater";
import { Product } from "../../core/products/domain/Product";
import { ProductRepository } from "../../core/products/domain/ProductRepository";
import { ApiProductRepository } from "../../core/products/infrastructure/api/ApiProductRepository";
import { StockProps } from "./StockProps";
import { StockState } from "./StockState";

export class Stock extends React.Component<StockProps, StockState> {
  private _productRepository: ProductRepository = new ApiProductRepository();
  private _productLister: ProductLister;
  private _productStockUpdater: ProductStockUpdater;

  constructor(props: any) {
    super(props);
    this._productLister = new ProductLister(this._productRepository);
    this._productStockUpdater = new ProductStockUpdater(this._productRepository);
    this.state = {
      products: [],
    };
    this._productLister.invoke().then((e) =>
      this.setState({
        products: e,
      })
    );
  }
  render() {
    return (
      <>
        <table>
          {this.state.products.map((e: Product) => {
            return (
              <tr>
                <td>{e.name}</td>
                <td>{e.sku}</td>
                <td>
                    <input onChange={(a: any) => {
                        e.changeStock(parseFloat(a.target.value));
                        this.setState({});
                    }} type="number" value={e.stock}/>
                    <input onClick={async () => {
                        await this._productStockUpdater.invoke(e, e.stock);
                    }} type="button" value={"Guardar"} />           
                </td>                                
              </tr>
            );
          })}
        </table>
      </>
    );
  }
}
