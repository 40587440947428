import { Button, Card, TextField } from '@shopify/polaris';
import React, { useCallback, useState } from 'react';
import { RegisterProps } from './RegisterProps';
import { RegisterState, Error } from './RegisterState';
import './Register.css';
import { AdminRepository } from '../../core/admin/domain/AdminRepository';
import { ApiAdminRepository } from '../../core/admin/infrastructure/api/ApiAdminRepository';
import { AdminRegistrar } from '../../core/admin/application/register/AdminRegistrar';
import { Logo } from '../../components/Logo/Logo';
import { RouteComponentProps, withRouter } from 'react-router-dom';

class Register extends React.Component<RegisterProps, RegisterState> {
    private _repository: AdminRepository = new ApiAdminRepository();
    private _registrar: AdminRegistrar;
    constructor(props: any){
        super(props);
        this.state = {
            email: "" , 
            password: "" , 
            repeatedPassword: "" , 
            error: Error.NO_ERROR
        }
        this._registrar = new AdminRegistrar(this._repository);
        this.bindAll();
    }
    public render(){
        return (        
            <Card title={this.title}>                            
                <div className="register-form">
                    <TextField label="Correo electrónico" value={this.state.email} onChange={(e: string) => this.setState({ email: e })} type="email"></TextField>
                    <TextField helpText={this.unmatchedPasswordsText} error={this.unmatchedPasswords} label="Contraseña" value={this.state.password} onChange={(e: string) => this.setState({ password: e })} type="password"></TextField>
                    <TextField helpText={this.unmatchedPasswordsText} error={this.unmatchedPasswords} label="Repite contraseña" value={this.state.repeatedPassword} onChange={(e: string) => this.setState({ repeatedPassword: e })} type="password"></TextField>
                    <div className="register-button">
                        <Button size={'large'} onClick={this.submitForm} primary={true} fullWidth={true}>Registrarse</Button>
                    </div>             
                    <div className="register-button">
                        <Button size={'large'} onClick={this.props.onLogin} fullWidth={true}>Iniciar sesión</Button>
                    </div>                  
                </div>
            </Card>
        );
    }
    public bindAll(){
        this.submitForm = this.submitForm.bind(this);
    }

    public async submitForm(){
        if(this.state.password !== this.state.repeatedPassword){            
            this.setState({ error: Error.PASSWORD_UNMATCH })
            return;
        }
        try{
            await this._registrar.invoke(this.state.email, this.state.password);
            this.props.onLogin();
        }catch(e: any){
            this.setState({ error: Error.SERVER_ERROR })
        }
    }

    public get unmatchedPasswordsText(): any{
        if(this.unmatchedPasswords){
            return ( <div style={{color: "red", textAlign: "left"}}>Las contraseñas no coinciden</div> )
        }
        return ( <div></div> )
    }
    public get unmatchedPasswords() : boolean {
        return this.state.error == Error.PASSWORD_UNMATCH;
    }
    

    public get title() : any {
        return ( <h1 style={{fontSize: "24px", fontWeight: "bold"}}>Registrate en multistorefy</h1>)
    }
}

export default Register;