import { ShopRepository } from "../../domain/ShopRepository";

export class AllShopFinder{
     private _repository: ShopRepository;
     constructor(repository: ShopRepository){
         this._repository = repository;
     }

    invoke(){
        return this._repository.all();
    }
}