import { Badge, Button, Card, Checkbox, DataTable, Modal, Pagination, ResourceItem, ResourceList, Tabs, TextContainer, TextField, TextStyle } from '@shopify/polaris';
import React, { useCallback, useState } from 'react';
import TransportApi from '../../data/transport/transport_api';
import ApiKeyField from '../api-key-field/Api-key-field';
import ShopSelector from '../shop-selector/Shop-selector';
import { v1 as uuidv1 } from 'uuid';

interface TransportData{
  name: string;
  defaultUrl: string;  
}

interface TransportTableProps{}
interface TransportTableState{
  rows:any[];
  selectedItems:any[];
  addingTransport: boolean;
  transportData: TransportData;
}

class TransportTable extends React.Component<TransportTableProps, TransportTableState>{
  private _columnContentTypes = ['text', 'text', 'text'];
  private _headings           = [ <Checkbox label="" checked={false} onChange={this.selectAll} />, 'Tienda', 'Confirmada'];
  private _rows               = [];
  private _selectedItems      = [];
  private _api: TransportApi     = new TransportApi();


  constructor(props: any){    
    super(props);     
    this.state = {
      rows: [],
      selectedItems: [],
      addingTransport: false,
      transportData: {
        name: "" , 
        defaultUrl: ""
      }
    }
    this.setSelectedItems = this.setSelectedItems.bind(this);
    this.refreshTransport     = this.refreshTransport.bind(this);  
  }

  public componentDidMount() {
      this.refreshTransport();
  }

  private refreshTransport(){
    this._api.getTransports().then(async (value: Response) => {
      let json: any = await value.json();
      this.setState({
        rows: json,
        selectedItems: []
      });      
    });
    
  }

  public componentWillUnmount() {
  }

  public get headings() : any[] {
    return this._headings;
  }  
  public get columnContentTypes() : any[] {
    return this._columnContentTypes;
  }
  
  public get rows() : any[] {
    return this._rows;
  }
  
  public handleShopCheckBox(){

  }

  public selectAll(){
  }
  
  public handleShopChange(shop: string){
  }

  public renderItem(row: any){            
    return (
      <ResourceItem
        id={row["id"]}        
        onClick={() => {
                    
        }}>
        <h3>
          <TextStyle variation="strong">{row["name"]}</TextStyle>                    
        </h3>                  
        {row["default_url"]}
      </ResourceItem>
    );
  }

  public setSelectedItems(value: any){
    this.setState({
      selectedItems: value
    })
  }

  public get bulkActions(): any[]{
    return [
      {
        content: 'Eliminar',
        onAction: async () => {
          for(var id of this.state.selectedItems){
            await this._api.deleteTransport(id);
          }
          this.refreshTransport();
        },
      },
    ];
  }




  render(){
    return (
    <div style={{width: "100%"}}>
      <Button onClick={() => {
        this.setState({
          addingTransport: !this.state.addingTransport
        });
      }}>
        Añadir transportista
      </Button>
      <ResourceList
        resourceName={{
          singular: "Transportista" , 
          plural: "Transportistas"
        }}
        items={this.state.rows}
        renderItem={this.renderItem}
        selectedItems={this.state.selectedItems}
        onSelectionChange={this.setSelectedItems}        
        bulkActions={this.bulkActions}
      />    
            <Modal        
        open={this.state.addingTransport}
        
        onClose={() => {
          
            this.setState({
              addingTransport: false
            });
        }}
        title="Añadir transportista"        
      >
        <Modal.Section>
          <TextField value={this.state.transportData.name}  onChange={(value: string) => {  
            this.setState({
              transportData: {
                name: value,
                defaultUrl: this.state.transportData.defaultUrl
              }
            })          
          }} label={"Nombre transportista"} />
          <TextField value={this.state.transportData.defaultUrl} onChange={(value: string) => {      
            this.setState({
              transportData: {
                name: this.state.transportData.name,
                defaultUrl: value
              }
            })             
          }} label={"URL de seguimiento"} />
          <TextContainer>
            <p>
              La url de seguimiento quedara: {this.state.transportData.defaultUrl}123456789
            </p>
          </TextContainer>
          <Button onClick={async () => {
            await this._api.addTransport(uuidv1(), this.state.transportData.name , this.state.transportData.defaultUrl);
            this.setState({
              addingTransport: false
            });
            this.refreshTransport();
          }}>Guardar</Button>
        </Modal.Section>
      </Modal>
    </div>);
  }
}






export default TransportTable;