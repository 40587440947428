export class LineItem{
    private _id: number;
    private _name: string;
    private _price: string;
    private _totalDiscount: number;
    private _productId: number;
    private _quantity: number;
    private _title: string;
    private _variantId: number;
    private _sku: string;
    private _fulfillmentStatus: string | null;
    constructor(
        id: number, 
        name: string, 
        price: string, 
        totalDiscount: number,
        productId: number, 
        quantity: number, 
        title: string, 
        variantId: number, 
        sku: string,
        fulfillmentStatus: string | null
    ) { 
        this._id = id;
        this._name = name;
        this._price = price;
        this._totalDiscount = totalDiscount;
        this._productId = productId;
        this._quantity = quantity;
        this._title = title;
        this._variantId = variantId;
        this._sku = sku;
        this._fulfillmentStatus = fulfillmentStatus;
    }

    public get id(): number{
        return this._id;
    }
    public get name(): string{
        return this._name;
    }
    public get price(): string{
        return this._price;
    }    
    public get totalDiscount() : number {
        return this._totalDiscount;
    }    
    public get productId(): number{
        return this._productId;
    }
    public get quantity(): number{
        return this._quantity;
    }
    public get title(): string{
        return this._title;
    }
    public get variantId(): number{
        return this._variantId;
    }
    public get sku() : string {
        return this._sku;
    }
    public get fulfillmentStatus(): string | null{
        return this._fulfillmentStatus;
    }

    static fromJson(json: any){        
        return new LineItem(
            json["id"] , 
            json["name"], 
            json["price"] , 
            json["total_discount"],
            json["product_id"] , 
            json["quantity"] , 
            json["title"] , 
            json["variant_id"] , 
            json["sku"],
            json["fulfillment_status"]
        )
    }
}