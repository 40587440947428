export class Customer{
    private _id: string;
    private _email: string;
    private _firstName: string;
    private _lastName: string;
    constructor(id: string, email: string, firstName: string, lastName: string){
        this._id = id;
        this._email = email;
        this._firstName = firstName;
        this._lastName = lastName;
    }
    
    public get id() : string {
        return this._id;
    }
    
    public get email() : string {
        return this._email;
    }
    
    public get firstName() : string {
        return this._firstName;
    }
    
    public get lastName() : string {
        return this._lastName;
    }

    
    public get fullName() : string {
        return (this._firstName + " " + this._lastName).replaceAll("null" , "");
    }
    

    static fromJson(json: any){
        
        return new Customer(json["id"] , json["email"], json["first_name"] , json["last_name"]);
    }    
    
    
    
}