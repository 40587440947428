import React, { useCallback, useState } from 'react';

export interface RegisterState{
    email: string;
    password: string;
    repeatedPassword: string;    
    error: Error;
}
export enum Error{
    PASSWORD_UNMATCH, SERVER_ERROR, NO_ERROR
}