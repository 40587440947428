import { Shop } from "../../domain/Shop";
import { ShopRepository } from "../../domain/ShopRepository";

export class ApiShopRepository extends ShopRepository { 
    private _url: string = "https://api.multistorefy.appsbealion.com/api/admin/shop"
    async all(): Promise<Shop[]>{
        let response: Response = await fetch(this._url + "/all", {
            headers: {
                'X-Token': localStorage.getItem("token") ?? ""
            }
        });
        let json = await response.json();
        return json.map((e: any) => this.jsonToShop(e))
    }
    async confirmed(): Promise<Shop[]> { 
        let response: Response = await fetch(this._url + "/confirmed", {
            headers: {
                'X-Token': localStorage.getItem("token") ?? ""
            }
        }); 
        let json = await response.json();
        return json.map((e: any) => this.jsonToShop(e))
    }
    async valid(shop: Shop): Promise<any>{
        let response: Response = await fetch(this._url + "/valid/" + shop.name, {
            headers: {
                'X-Token': localStorage.getItem("token") ?? ""
            }
        });
        let json = await response.json();
        return json;
    }
    async delete(shop: Shop): Promise<any> {
        let formData = new FormData();
        formData.append("shop_name" , shop.id);
        let response: Response = await fetch(this._url + "/delete" , { 
            method: "POST" , 
            body: formData ,
            headers: {
                'X-Token': localStorage.getItem("token") ?? ""
             }
        });
        if(response.status !== 200){
            throw Error("Error");
        }
    }
    async confirm(shop: Shop): Promise<any> {
        let formData = new FormData();
        formData.append("shop_id" , shop.id);
        let response: Response = await fetch(this._url + "/confirm" , { 
            method: "POST" , 
            body: formData ,
            headers: {
                'X-Token': localStorage.getItem("token") ?? ""
             }
        });
        if(response.status !== 200){
            throw Error("Error");
        }
        shop.changeConfirmed(true);
    }

    async addNote(shop: Shop, note: string): Promise<any> { 
        let formData = new FormData();
        formData.append("note" , note);
        let response: Response = await fetch(this._url + "/note/" + shop.id, {
            method: "POST",
            body: formData,
            headers: {
                'X-Token': localStorage.getItem("token") ?? ""
            }
        });
        if(response.status !== 200){
            throw Error("Error");
        }        
    }

    async privateInstall(shop: string, apiKey: string, secret: string): Promise<any> { 

        let formData = new FormData();
        formData.append("shop" , shop);
        formData.append("api_key" , apiKey);
        formData.append("secret" , secret);
        let response: Response = await fetch(this._url + "/syncro", {
            method: "POST", 
            body: formData , 
            headers: {
                'X-Token' : localStorage.getItem("token") ?? ""
            }
        });
        if(response.status !== 200){
            throw Error("Error");
        }
    }


    private jsonToShop(json: any): Shop { 
        return new Shop(json["id"] , json["name"], json["confirmed"], json["note"]);
    }


    
}



