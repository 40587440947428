import { Button, TextField } from '@shopify/polaris';
import React, { useCallback, useState } from 'react';
import {ClipboardMinor} from '@shopify/polaris-icons';
import {Icon} from '@shopify/polaris';
import ApiKeyApi from '../../../data/api_key/api_key_api';
import { ApiKeyFieldProps } from './ApiKeyFieldProps';
import { ApiKeyFieldState } from './ApiKeyFieldState';


export class ApiKeyField extends React.Component<ApiKeyFieldProps, ApiKeyFieldState>{
    private _apiKeyApi: ApiKeyApi = new ApiKeyApi();
    private _apiKeyInput: React.RefObject<any>;
    constructor(props: any){        
        super(props);
        this.state = { 
            apikey: ""
        };
        this._apiKeyInput = React.createRef();
        this.copyKey = this.copyKey.bind(this);
    }    
    componentDidMount(){
        this._apiKeyApi.getApiKey().then(async (result: Response) => {
            this.setState({
                apikey: JSON.parse(await result.text())["api_key"]
            });
        });
    }
    copyKey(){
        this._apiKeyInput.current.select();
        this._apiKeyInput.current.setSelectionRange(0, 9999);
        document.execCommand("copy");
    }

    render(){
        return (
            <div>
                <h1>Comparte tu clave con las tiendas que quieras gestionar</h1>
                <div style={{display: "flex", alignItems: "end", justifyContent: "end" ,maxWidth: "615px"}}>
                    <input ref={this._apiKeyInput} type="text" style={{position: "fixed", top: "-9999px"}} value={this.state.apikey}/>
                    <div style={{flex: "1"}}><TextField label="" value={this.state.apikey} disabled={true}/></div>
                    <Button onClick={this.copyKey}>Copiar</Button>
                </div>
            </div>
        );
    }
}
