import React from "react";
import {ReportsState} from "./ReportsState";
import {ReportsProps} from "./ReportsProps";
import { OrderRepository } from "../../core/orders/domain/OrderRepository";
import { ApiOrderRepository } from "../../core/orders/infrastructure/api/ApiOrderRepository";
import { ShopOrderOfIdFinder } from "../../core/orders/application/find/ShopOrderOfIdFinder";
import { ShopOrdersFinder } from "../../core/orders/application/find/ShopOrdersFinder";
import { Shop } from "../../core/shops/domain/Shop";
import { Order } from "../../core/orders/domain/Order";
import { Button, Card, Collapsible, Range, TextField } from "@shopify/polaris";
import { LineItem } from "../../core/orders/domain/LineItem";
import { ShopRepository } from "../../core/shops/domain/ShopRepository";
import { ApiShopRepository } from "../../core/shops/infrastructure/api/ApiShopRepository";
import { AllShopFinder } from "../../core/shops/application/find/AllShopFinder";
import { ShopValidChecker } from "../../core/shops/application/valid/ShopValidChecker";
import { ProductRepository } from "../../core/products/domain/ProductRepository";
import { ApiProductRepository } from "../../core/products/infrastructure/api/ApiProductRepository";
import { ProductLister } from "../../core/products/application/list/ProductLister";
import { Product } from "../../core/products/domain/Product";
import { DatePopover } from "../Shared/DatePopover/DatePopover";
import { connectableObservableDescriptor } from "rxjs/internal/observable/ConnectableObservable";
import { ProductCollapsible } from "./containers/ProductCollapsible/ProductCollapsible";
import { ProductOfShopLister } from "../../core/products/application/list/ProductOfShopLister";
import { collapseTextChangeRangesAcrossMultipleVersions } from "typescript";

export class Reports extends React.Component<ReportsProps, ReportsState>{
    private _months: string[]       = ["Enero" , "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
    private _balearCost: number     = 7.99;
    private _peninsularCost: number = 6.24;
    private _portugalCost: number   = 8.50;
    private _repository: OrderRepository = new ApiOrderRepository();    
    private _finder: ShopOrdersFinder;

    private _shopRepository: ShopRepository = new ApiShopRepository();
    private _allShopFinder: AllShopFinder;
    private _checker: ShopValidChecker;

    private _productRepository: ProductRepository = new ApiProductRepository();
    private _productLister: ProductLister;
    private _productOfShopLister: ProductOfShopLister;

    private _shops: Shop[] = [];
    constructor(props: any){
        super(props);
        this.state = {
            products: [],
            orders: [], 
            shops: [], 
            shopProducts: {}, 
            dateRangeFilter: {
                start: new Date(new Date().setDate(new Date().getDate() - 30)),
                end: new Date()                
            },
            fromOrder: null, 
            toOrder: null
        }
        this._finder              = new ShopOrdersFinder(this._repository);
        this._allShopFinder       = new AllShopFinder(this._shopRepository);
        this._checker             = new ShopValidChecker(this._shopRepository);
        this._productLister       = new ProductLister(this._productRepository);
        this._productOfShopLister = new ProductOfShopLister(this._productRepository);
        this.loadOrders           = this.loadOrders.bind(this);
        this.render               = this.render.bind(this);
    }
    sleep(ms: number) {
        return new Promise(resolve => setTimeout(resolve, ms));
      }
    public async componentDidMount(){     
        try{
            var products: Product[] = await this._productLister.invoke();
            this.setState({
                products: products
            });            
                
            var shops: Shop[] = await this._allShopFinder.invoke();            
                
            for(var shop of shops){
                await this.sleep(500);
                var e: Product[] = await this._productOfShopLister.invoke(shop);    
                this.state.shopProducts[shop.name] = e;
                this.setState({
                    shopProducts: this.state.shopProducts
                })
                this.state.shops.push(shop);
                this.setState({ shops: this.state.shops });
                await this.sleep(500); 
                await this.loadOrders(shop, false);
            }                               
        }catch(e: any){
            
        }
       
    }
    private async loadOrders(shop: Shop, sinceId: Order | boolean){
        var value: [Order[], Order | boolean] = await this._finder.invoke(shop, sinceId);
        let orders    = value[0];
        let lastOrder = value[1];
        this.setState({ orders: this.state.orders.concat(...orders) });
        if(lastOrder !== false){                       
                await this.sleep(500);     
                await this.loadOrders(shop, lastOrder);            
        }        
    }

    render(){
        return (
            <>     
                <DatePopover range={this.state.dateRangeFilter} onDateChange={(range: Range) => {    
                    this.setState({
                        dateRangeFilter: range
                    });
                }} opened={false} onOpened={(value: boolean) => {
                }}></DatePopover>
                <div style={{display: "flex", alignItems: "flex-end"}}>
                <TextField label={"Desde pedido nº: "} value={this.state.fromOrder} onChange={(value: string) => {
                    this.setState({
                        fromOrder: value
                    })
                }} />
                <TextField label={"Hasta pedido nº: "}  value={this.state.toOrder} onChange={(value: string) => {
                    this.setState({
                        toOrder: value
                    })
                }} />
                </div>
                <div>{this.renderList}</div>
            </>
        );
    }

    renderWeeks(orders: any){
        Object.keys(orders).map((week: string, index: number) => {
            var o = orders[week];                  
            var first = o[0];
            var last = o[o.length - 1];
            var firstNum = first.orderNum;
            var lastNum = last.orderNum;
            return (
                <div>
                    {firstNum} - {lastNum}
                </div>
            )
        });
        return (
            <div>
                
            </div>
        )
    }

    get renderList(){
        return (            
            this.state.shops.map((e: Shop) => {     
                var months  = this.weekOrders(e);   
                var monthRenderer = Object.keys(months).map((month: string, index: number) => {
                    var weeks     = months[month];
                    var weeksKeys = Object.keys(weeks);                    
                    return (
                        <>
                        <table style={{border: "solid 1px"}}>
                            <tbody>
                                <tr>
                                    <th style={{textAlign: "center"}} colSpan={4}>{this._months[parseInt(month)]}</th>
                                </tr>
                                <tr>
                                    <th style={{textAlign: "center"}} colSpan={4}>
                                        
                                    </th>
                                </tr>            
                                <tr>
                                {
                                    weeksKeys.map((week: string) => {
                                        var orders     = months[month][week];
                                        var firstOrder = months[month][week][0];
                                        var lastOrder  = months[month][week][months[month][week].length - 1];
                                        var products   = this.getTotalOfProductQuantities(orders);
                                        return <>                                            
                                                <th>{ parseInt(week) + 1 } SEMANA                                                     
                                                    <br></br> 
                                                    Del dia {firstOrder.date.getDate()} al dia {lastOrder.date.getDate()} 
                                                    <br></br>                                                                                                                                                                                               
                                                    Del pedido {firstOrder.orderNum} al pedido {lastOrder.orderNum}
                                                    <table>
                                                        <tr style={{background: "#ffefc8"}}>
                                                            <td style={{textAlign: "left"}}>
                                                                Total envios peninsulares ({this.peninsularFilter(orders).length}): </td>
                                                                <td style={{textAlign: "right"}}>{this.calculateTotalPeninsularCost(this.peninsularFilter(orders))}
                                                            </td>
                                                        </tr>
                                                        <tr style={{background: "#ffefc8"}}>
                                                            <td style={{textAlign: "left"}}>
                                                            Total envios baleares ({this.balearicFilter(orders).length}):
                                                            </td>
                                                            <td style={{textAlign: "right"}}> 
                                                                {this.calculateTotalBalearicShipCost(this.balearicFilter(orders))}
                                                            </td>
                                                        
                                                        </tr>
                                                        <tr style={{background: "#ffefc8"}}>
                                                                <td style={{textAlign: "left"}}>
                                                                    Total envios portugal ({this.portugalFilter(orders).length}):
                                                                </td>
                                                                <td style={{textAlign: "right"}}> 
                                                                    {this.calculateTotalPortugalShipCost(this.portugalFilter(orders))}
                                                                </td>
                                                        </tr>
                                                        <tr style={{textAlign: "left"}}>
                                                            <td style={{textAlign: "left"}}>Total facturacion tienda ({orders.length}) :</td><td style={{textAlign: "right"}}>{this.calculateTotalBillingShop(orders)}</td>
                                                        </tr>
                                                        {/* <tr style={{textAlign: "left"}}>
                                                            <td style={{textAlign: "left"}}>Total pagos con tarjeta: </td><td style={{textAlign: "right"}}>{this.calculateTotalAlternativePayment(orders)}</td>
                                                        </tr> */}
                                                        <tr style={{textAlign: "left"}}>
                                                            <td style={{textAlign: "left"}}>
                                                                Total coste productos: 
                                                            </td>
                                                            <td style={{textAlign: "right"}}>
                                                                {this.calculateTotalCostProducts(orders, e)}
                                                            </td>                                                            
                                                        </tr>
                                                        <tr style={{textAlign: "left"}}>
                                                            <td style={{textAlign: "left", borderTop: "solid 1px"}}>
                                                                Total:
                                                            </td>
                                                            <td style={{textAlign: "right", borderTop: "solid 1px"}}>
                                                                {this.calculateTotal(orders, e)}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan={2}>
                                                            <table style={{"borderWidth":"1px", 'borderColor':"#aaaaaa", 'borderStyle':'solid'}}>
                                                                {
                                                                Object.keys(products).map((e: string) => {
                                                                        return <tr style={{"borderWidth":"1px", 'borderColor':"#aaaaaa", 'borderStyle':'solid'}}><td style={{"borderWidth":"1px", 'borderColor':"#aaaaaa", 'borderStyle':'solid'}}>{e}</td><td style={{fontSize: "22px", fontWeight: "bold",borderWidth:"1px", borderColor:"#aaaaaa", borderStyle:'solid'}}>{products[e]}</td></tr>
                                                                })   
                                                                }
                                                            </table>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </th>
                                               </>
                                    })
                                }           
                                </tr>                 
                            </tbody>
                            </table>
                        </>
                    )


                });
                var products = this.totalOfProductsQuantities(e);
                return <div key={"container-" + e.name} style={{margin: "1em"}}>
                    <Card>
                        <div style={{display: "flex"}}> 
                            {monthRenderer}                                                    
                        </div>                        
                        <div style={{display: "flex", flexDirection: "column", padding: "1em"}}>
                            <div style={{fontSize: "18px", fontWeight: "bold", marginTop: "8px", marginBottom: "8px"}}>{e.name}</div>
                            <div style={{padding: "4px"}}>Total envios baleares ({this.balearicOrders(e).length}): {this.totalBalearicShipCost(e)}</div>
                            <div style={{padding: "4px"}}>Total envios peninsulares ({this.peninsularOrders(e).length}): {this.totalPeninsularShipCost(e)}</div>
                            <div style={{padding: "4px"}}>Total envios portugal ({this.portugalOrders(e).length}): {this.totalPortugalShipCost(e)}</div>
                            <div style={{padding: "4px"}}>Total facturación tienda ({this.sendTagOrders(e).filter((value: Order) => value.isCod == true).length}): {this.totalBillingShop(e)}</div>
                            <div style={{padding: "4px"}}>Total coste productos: {this.totalCostProducts(e)} </div>
                            <hr></hr>
                            <div  style={{padding: "4px"}}>Total: {this.total(e)}</div>
                            <hr></hr>
                            <div style={{padding: "4px"}}>Primer pedido: {this.firstOrder(e).name} </div>
                            <div style={{padding: "4px"}}>Ultimo pedido: {this.lastOrder(e).name} </div>
                            <hr></hr>
                            <table style={{"borderWidth":"1px", 'borderColor':"#aaaaaa", 'borderStyle':'solid'}}>
                            {
                               Object.keys(products).map((e: string) => {
                                    return <tr style={{"borderWidth":"1px", 'borderColor':"#aaaaaa", 'borderStyle':'solid'}}><td style={{"borderWidth":"1px", 'borderColor':"#aaaaaa", 'borderStyle':'solid'}}>{e}</td><td style={{fontSize: "22px", fontWeight: "bold",borderWidth:"1px", borderColor:"#aaaaaa", borderStyle:'solid'}}>{products[e]}</td></tr>
                               })   
                            }
                            </table>
                        </div>
                        {this.state.shopProducts[e.name].length > 0 ? <ProductCollapsible list={this.state.shopProducts[e.name]}></ProductCollapsible> : <div></div> }                   
                    </Card>
                </div>
            })                    
        );
    }

    private ordersOfShop(e: Shop){
        return this.filterByOrderRange(this.filterByDate(this.state.dateRangeFilter, this.state.orders.filter((value: Order) => value.shop == e.name)));
    }

    

    private sendTagOrders(e: Shop){      
        
        return this.ordersOfShop(e).filter((e: Order) => e.tags.toLowerCase().includes("enviar"));
    }


    private weekOrders(e: Shop): any{        
            var weekOrders: any = {};
            var orders  = this.sendTagOrders(e);
            for(var order of orders){                
                let week  = this.getWeek(order.date);
                let month = order.date.getMonth();
                if(!weekOrders[month]){
                    weekOrders[month] = {};
                }
                if(!weekOrders[month][week]){
                    weekOrders[month][week] = [];
                }
                weekOrders[month][week].push(order);
                weekOrders[month][week] = weekOrders[month][week].sort((a: Order , b: Order) => {                    
                    if(a.date.getTime() < b.date.getTime()){
                        return -1;
                    }
                    if(a.date.getTime() > b.date.getTime()){
                        return 1;
                    }
                    return 0;                      
                });                
            }     
            return weekOrders;                   
    }
    



    private firstOrder(e: Shop){
        let orders = this.sendTagOrders(e);
        return typeof orders[0] == "undefined" ? {
            name: ""
        } : orders[0];
    }
    private lastOrder(e: Shop){
        let orders = this.sendTagOrders(e);
        return typeof orders[orders.length - 1] == "undefined" ? {
            name: ""
        } :  orders[orders.length - 1];
    }

    private filterByOrderRange(items: Order[]){
        var from = this.state.fromOrder;
        var to   = this.state.toOrder;

        if(from !== null && from.trim().length > 0 && (to == null || to.trim().length == 0)){
            var fromNumber = parseInt(from);            
            var filteredItems = items.filter((e: Order) => e.orderNum >= fromNumber);
            return filteredItems;
        }    

        if(from == null || to == null){
            return items;
        }
        if(from.trim().length == 0 || to.trim().length == 0){
            return items;
        }

        var fromNumber = parseInt(from);
        var toNumber = parseInt(to);
        var filteredItems = items.filter((e: Order) => e.orderNum >= fromNumber && e.orderNum <= toNumber);


        return filteredItems;
    }


    private balearicOrders(e: Shop){
        return this.sendTagOrders(e)
        .filter((e: Order) => e.shippingAddress !== null)
        .filter((e: Order) => e.shippingAddress?.zip !== null)
        .filter((e: Order) => e.shippingAddress?.zip.startsWith("07"))      
        .filter((e: Order) => e.shippingAddress?.zip.indexOf("-") == -1);  
    }

    private balearicFilter(orders: Order[]){
        return orders  
        .filter((e: Order) => e.shippingAddress !== null)
        .filter((e: Order) => e.shippingAddress?.zip !== null)
        .filter((e: Order) => e.shippingAddress?.zip.startsWith("07"))
        .filter((e: Order) => e.shippingAddress?.zip.indexOf("-") == -1);
    }


    private peninsularOrders(e: Shop){
        return this.sendTagOrders(e)
        .filter((e: Order) => e.shippingAddress !== null)
        .filter((e: Order) => e.shippingAddress?.zip !== null)
        .filter((e: Order) => !e.shippingAddress?.zip.startsWith("07"))
        .filter((e: Order) => e.shippingAddress?.zip.indexOf("-") == -1);
    }



    private peninsularFilter(orders: Order[]){
        return orders
        .filter((e: Order) => e.shippingAddress !== null)
        .filter((e: Order) => e.shippingAddress?.zip !== null)
        .filter((e: Order) => !e.shippingAddress?.zip.startsWith("07"))  
        .filter((e: Order) => e.shippingAddress?.zip.indexOf("-") == -1);      
    }

    private portugalOrders(e: Shop){
        return this.sendTagOrders(e)
        .filter((e: Order) => e.shippingAddress !== null)
        .filter((e: Order) => e.shippingAddress?.zip !== null)
        .filter((e: Order) => !e.shippingAddress?.zip.startsWith("07"))
        .filter((e: Order) => e.shippingAddress?.zip.indexOf("-") !== -1);
    }

    private portugalFilter(orders: Order[]){
        return orders
        .filter((e: Order) => e.shippingAddress !== null)
        .filter((e: Order) => e.shippingAddress?.zip !== null)
        .filter((e: Order) => !e.shippingAddress?.zip.startsWith("07"))  
        .filter((e: Order) => e.shippingAddress?.zip.indexOf("-") !== -1);      
    }






    private filterByDate(range: Range, items: Order[]) {
        let start = range.start;
        let end   = range.end;
        let filteredItems = items.filter((e: Order) => {           
          if(typeof e === "undefined" || e == null || !e){
            return null;
          }      
          let timestamp = Date.parse(e.createdAt);
          let date = new Date();
          date.setTime(timestamp);
          if (date.getTime() >= start.getTime() && date.getTime() <= end.getTime()) {
            return e;
          }
          return null;
        });
        return filteredItems;
    }

    private totalCostProducts(e: Shop){
        let ids = this.sendTagOrders(e).map((e: Order) => e.lineItems.map((e: LineItem) => {            
            return {
                productId: e.productId , 
                quantity: e.quantity
            };
        }).flat()).flat();
        
        let orderShopProducts  = ids.map((id: any) => this.state.shopProducts[e.name].map((e: Product) => {
            if( e.id === id.productId ){
                return {
                    sku: e.sku, 
                    quantity: id.quantity
                }
            }
            return null;
        })).flat().filter((e: any) => e !== null);
        let orderExcelProducts = orderShopProducts.map((p1: any) => this.state.products.map((p2: Product) => {
            if(p1.sku == p2.sku){
                return {
                    price: p2.price * p1.quantity
                }
            }
            return null
        })).flat().filter((e: any) => e !== null);        
        let totalExcelProfit   = orderExcelProducts.map((e: any) => e.price).reduce((a: number, b: number) => a + b, 0);
        return totalExcelProfit.toFixed(2).toString().replace('.' , ',');        
    }

    private calculateTotalCostProducts(orders: Order[], e: Shop){
        let ids = orders.map((e: Order) => e.lineItems.map((e: LineItem) => {            
            return {
                productId: e.productId , 
                quantity: e.quantity
            };
        }).flat()).flat();        
        let orderShopProducts  = ids.map((id: any) => this.state.shopProducts[e.name].map((e: Product) => {
            if( e.id === id.productId ){
                return {
                    sku: e.sku, 
                    quantity: id.quantity
                }
            }
            return null;
        })).flat().filter((e: any) => e !== null);
        let orderExcelProducts = orderShopProducts.map((p1: any) => this.state.products.map((p2: Product) => {
            if(p1.sku == p2.sku){
                return {
                    price: p2.price * p1.quantity
                }
            }
            return null
        })).flat().filter((e: any) => e !== null);        
        let totalExcelProfit   = orderExcelProducts.map((e: any) => e.price).reduce((a: number, b: number) => a + b, 0);
        return totalExcelProfit.toFixed(2).toString().replace('.' , ',');        
    }



    private totalOfProductsQuantities(e: Shop){
        let ids = this.sendTagOrders(e).map((e: Order) => e.lineItems.map((e: LineItem) => {
            let a: any = [];
            for (let index = 0; index < e.quantity; index++) {
                a.push(e.name);
            }
            return a;
        }).flat()).flat();
        let result = ids.reduce((a: any, c: any) => (a[c] = (a[c] || 0) + 1, a), Object.create(null));

        return result;
    }

    private getTotalOfProductQuantities(orders: Order[]){
        let ids = orders.map((e: Order) => e.lineItems.map((e: LineItem) => {
            let a: any = [];
            for (let index = 0; index < e.quantity; index++) {
                a.push(e.name);
            }
            return a;
        }).flat()).flat();
        let result = ids.reduce((a: any, c: any) => (a[c] = (a[c] || 0) + 1, a), Object.create(null));
        return result;
    }

    private getWeek(date: Date){
        var firstWeekday = new Date(date.getFullYear(), date.getMonth(), 1).getDay();
        var offsetDate = date.getDate() + firstWeekday - 1;        
        return Math.floor(offsetDate / 7);
      }
      

    private totalCostProductsExcel(shop: Shop){
        let ids = this.sendTagOrders(shop).map((order: Order) => order.lineItems.map((e: LineItem) => {            
            return {
                productId: e.productId , 
                quantity: e.quantity,
                orderNum: order.orderNum
            };
        }).flat()).flat();
        
        let orderShopProducts  = ids.map((id: any) => this.state.shopProducts[shop.name].map((e: Product) => {
            if( e.id === id.productId ){
                return {
                    sku: e.sku, 
                    quantity: id.quantity , 
                    orderNum: id.orderNum
                }
            }
            return null;
        })).flat().filter((e: any) => e !== null);        
        let orderExcelProducts = orderShopProducts.map((p1: any) => this.state.products.map((p2: Product) => {
            if(p1.sku == p2.sku){
                return {
                    total: p2.price * p1.quantity,
                    price: p2.price , 
                    quantity: p1.quantity
                }
            }
            return null;
        })).flat().filter((e: any) => e !== null);        
        let totalExcelProfit   = orderExcelProducts.map((e: any) => e.total).reduce((a: number, b: number) => a + b, 0);
        return totalExcelProfit;        
    }

    private calculateCostProductsExcel(orders: Order[] , shop: Shop){
        let ids = orders.map((order: Order) => order.lineItems.map((e: LineItem) => {            
            return {
                productId: e.productId , 
                quantity: e.quantity,
                orderNum: order.orderNum
            };
        }).flat()).flat();
        
        let orderShopProducts  = ids.map((id: any) => this.state.shopProducts[shop.name].map((e: Product) => {
            if( e.id === id.productId ){
                return {
                    sku: e.sku, 
                    quantity: id.quantity , 
                    orderNum: id.orderNum
                }
            }
            return null;
        })).flat().filter((e: any) => e !== null);        
        let orderExcelProducts = orderShopProducts.map((p1: any) => this.state.products.map((p2: Product) => {
            if(p1.sku == p2.sku){
                return {
                    total: p2.price * p1.quantity,
                    price: p2.price , 
                    quantity: p1.quantity
                }
            }
            return null;
        })).flat().filter((e: any) => e !== null);        
        let totalExcelProfit   = orderExcelProducts.map((e: any) => e.total).reduce((a: number, b: number) => a + b, 0);
        return totalExcelProfit;       
    }


    private productOfId(shop: Shop, id: number){
        return this.state.shopProducts[shop.name].filter((value: Product) => value.id == id)[0];
    }

    private productOfSku(sku: any){
        return this.state.products.filter((value: Product) => value.sku == sku);
    }

    private totalPeninsularShipCost(e: Shop){
        return this.peninsularOrders(e)
        .map((e: Order) => this._peninsularCost)
        .reduce((a: number, b: number) => a+b, 0)
        .toFixed(2).toString().replace('.' , ',');
    }

    private calculateTotalPeninsularCost(orders: Order[]){
        return orders        
        .map((e: Order) => this._peninsularCost)
        .reduce((a: number, b: number) => a+b, 0)
        .toFixed(2).toString().replace('.' , ',');
    }

    private totalBalearicShipCost(e: Shop){
        return this.balearicOrders(e)
        .map((e: Order) => this._balearCost)
        .reduce((a: number, b: number) => a+b, 0)
        .toFixed(2).toString().replace('.' , ',');
    }

    private calculateTotalBalearicShipCost(orders: Order[]){
        return orders        
        .map((e: Order) => this._balearCost)
        .reduce((a: number, b: number) => a+b, 0)
        .toFixed(2).toString().replace('.' , ',');
    }


    private totalPortugalShipCost(e: Shop){
        return this.portugalOrders(e)
        .map((e: Order) => this._balearCost)
        .reduce((a: number, b: number) => a+b, 0)
        .toFixed(2).toString().replace('.' , ',');
    }

    private calculateTotalPortugalShipCost(orders: Order[]){
        return orders        
        .map((e: Order) => this._portugalCost)
        .reduce((a: number, b: number) => a+b, 0)
        .toFixed(2).toString().replace('.' , ',');
    }


    private calculateTotalBillingShop(orders: Order[]){        
        return orders
        .filter((value: Order) => value.isCod == true)
        .map((e: Order) => e.lineItems.map((e: LineItem) => (parseFloat(e.price)  * e.quantity) - e.totalDiscount)
        .reduce((a: number, b: number) => a + b))
        .reduce((a: number, b: number) => a + b , 0)
        .toFixed(2).toString().replace('.' , ',');
    }

    private calculateTotalAlternativePayment(orders: Order[]){
        var calculatedOrders = orders
        .filter((value: Order) => value.isCod == false)
        .map((e: Order) => e.lineItems.map((e: LineItem) => (parseFloat(e.price)  * e.quantity) - e.totalDiscount)
        .reduce((a: number, b: number) => a + b))
        .reduce((a: number, b: number) => a + b , 0)
        .toFixed(2).toString().replace('.' , ',');
        return calculatedOrders;
    }

    private totalBillingShop(e: Shop){
        return this.sendTagOrders(e)
        .filter((value: Order) => value.isCod == true)
        .map((e: Order) => e.lineItems.map((e: LineItem) => (parseFloat(e.price)  * e.quantity) - e.totalDiscount)
        .reduce((a: number, b: number) => a + b))
        .reduce((a: number, b: number) => a + b , 0)
        .toFixed(2).toString().replace('.' , ',');
    }    

    


    private total(e: Shop){
        let shipCosts = this.peninsularOrders(e)
        .map((e: Order) => this._peninsularCost)
        .reduce((a: number, b: number) => a+b, 0) 
        + 
        this.balearicOrders(e)
        .map((e: Order) => this._balearCost)
        .reduce((a: number, b: number) => a+b, 0)
        +
        this.portugalOrders(e)
        .map((e: Order) => this._balearCost)
        .reduce((a: number, b: number) => a+b, 0);

        let billingShop = this.sendTagOrders(e)
        .map((e: Order) => e.lineItems.map((e: LineItem) => (parseFloat(e.price)  * e.quantity) - e.totalDiscount)
        .reduce((a: number, b: number) => a + b))
        .reduce((a: number, b: number) => a + b , 0);
        let productCosts = this.totalCostProductsExcel(e);           
        return (billingShop - productCosts - shipCosts).toFixed(2).toString().replace('.' , ',');
    }

    private calculateTotal(orders: Order[], shop: Shop){
        let shipCosts = this.peninsularFilter(orders)  
        .map((e: Order) => this._peninsularCost)
        .reduce((a: number, b: number) => a+b, 0) 
        +
        this.balearicFilter(orders).map((e: Order) => this._balearCost)
        .reduce((a: number, b: number) => a+b, 0)
        +
        this.portugalFilter(orders).map((e: Order) => this._portugalCost)
        .reduce((a: number, b: number) => a+b, 0);
        let billingShop = orders
        .map((e: Order) => e.lineItems.map((e: LineItem) => (parseFloat(e.price)  * e.quantity) - e.totalDiscount)
        .reduce((a: number, b: number) => a + b))
        .reduce((a: number, b: number) => a + b , 0);

        let productCosts = this.calculateCostProductsExcel(orders, shop);
        return (billingShop - productCosts - shipCosts).toFixed(2).toString().replace('.' , ',');
    }



    private canceled(order: Order){
        return order.isCancelled == true;
    }
    private notCancelled(order: Order){
        return order.isCancelled != true;
    }

    public totalOfShop(e: Shop){  
        return this.ordersOfShop(e).filter((value: Order) => value.isCancelled !== true ).map((e: Order) => e.lineItems.map((e: LineItem) => (parseFloat(e.price)  * e.quantity) - e.totalDiscount).reduce((a: number, b: number) => a + b)).reduce((a: number, b: number) => a + b , 0).toFixed(2).toString().replace('.' , ',');
    }
    public totalPeninsular(e: Shop){
        return this.ordersOfShop(e).filter((value: Order) => value.isCancelled !== true ).filter((e: Order) => e.shippingAddress?.zip == null ? false : !e.shippingAddress?.zip.startsWith("07")).map((e: Order) => e.lineItems.map((e: LineItem) => (parseFloat(e.price)  * e.quantity) - e.totalDiscount).reduce((a: number, b: number) => a + b)).reduce((a: number, b: number) => a + b , 0).toFixed(2).toString().replace('.' , ',');
    }
    public totalBalear(e: Shop){
        return this.ordersOfShop(e).filter((value: Order) => value.isCancelled !== true ).filter((e: Order) => e.shippingAddress?.zip == null ? false : e.shippingAddress?.zip.startsWith("07")).map((e: Order) => e.lineItems.map((e: LineItem) => (parseFloat(e.price)  * e.quantity) - e.totalDiscount).reduce((a: number, b: number) => a + b)).reduce((a: number, b: number) => a + b , 0).toFixed(2).toString().replace('.' , ',');
    }
    public totalWithoutZip(e: Shop){
        return this.ordersOfShop(e).filter((value: Order) => value.isCancelled !== true ).filter((e: Order) => e.shippingAddress?.zip == null).map((e: Order) => e.lineItems.map((e: LineItem) => (parseFloat(e.price)  * e.quantity) - e.totalDiscount).reduce((a: number, b: number) => a + b)).reduce((a: number, b: number) => a + b , 0).toFixed(2).toString().replace('.' , ',');
    }







    



}


interface ProductIdLineItem{
    id: string;
    quantity: number;
}
interface ProductSKULineItem{
    sku: string;
    quantity: number;
}

interface WeekOrder{
    [key: number]: Order[];
}

interface MonthOrder{
    [key: number]: WeekOrder[];
}