import { v1 } from "uuid";
import { Admin } from "../../domain/Admin";
import { AdminRepository } from "../../domain/AdminRepository";

export class ApiAdminRepository extends AdminRepository {
    private _url: string = "https://api.multistorefy.appsbealion.com/api/admin";
    async register(email: string, password: string){
        const requestOptions = {
            method: "POST" , 
            headers: {"Content-type": "application/json"} , 
            body: JSON.stringify({id: v1(), email: email, password: password})
        };
        let response: Response = await fetch(this._url + "/register", requestOptions);
        if(response.status !== 200){
            throw new Error("Error registering");
        }
    }
    async auth(email: string, password: string): Promise<Admin>{
        const requestOptions = {
            method: "POST" , 
            headers: {"Content-Type": "application/json"} , 
            body: JSON.stringify({email: email, password: password})
        }
        let response: Response = await fetch(this._url + "/auth", requestOptions);
        if(response.status !== 200){
            throw new Error("Error " + response.status + ": " + response.statusText);
        }
        let result = await response.json();
        let admin: Admin = new Admin(email, result["token"]);
        return admin;         
    }
}