import { Product } from "../../domain/Product";
import { ProductRepository } from "../../domain/ProductRepository";

export class ProductStockUpdater{
    private _repository: ProductRepository;
    constructor(repository: ProductRepository){
        this._repository = repository;
    }
    async invoke(product: Product, stock: number){
        await this._repository.stock(product, stock);
    }
}