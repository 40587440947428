export class Product{
    private _id: any;
    private _sku: string;
    private _price: number; 
    private _name: string;
    private _stock: number;
    constructor(id: any, sku: string, price: number, name: string, stock: number){
        this._id = id;
        this._sku = sku;
        this._price = typeof price == "string" ? parseFloat(price) : price;
        this._name = name;
        this._stock = stock;
    }

    
    public get id() : any {
        return this._id;
    }
    
    
    public get stock() : number {
        return this._stock;
    }
    

    public get sku() : string {
        return this._sku;
    }
    
    public get price() : number {
        return this._price;
    }
    
    public get name() : string {
        return this._name;
    }

    changeStock(stock: number){
        this._stock = stock;
    }

    
    
    
}