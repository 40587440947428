import { Shop } from "../../../shops/domain/Shop";
import { Order } from "../../domain/Order";
import { OrderRepository } from "../../domain/OrderRepository";

export class ShopOrdersFinder{
    private _repository: OrderRepository;
    constructor(repository: OrderRepository){
        this._repository = repository;
    }
    invoke(shop: Shop, lastOrder?: Order | boolean){
        return this._repository.ofShop(shop, lastOrder);
    }
}