import { Shop } from "../../domain/Shop";
import { ShopRepository } from "../../domain/ShopRepository";

export class ShopConfirmer{
    private _repository: ShopRepository;
    constructor(repository: ShopRepository){
        this._repository = repository;
    }
    invoke(shop: Shop){
        return this._repository.confirm(shop);
    }
}