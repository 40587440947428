import { Badge, Button, Popover } from '@shopify/polaris';
import React, { useCallback, useState } from 'react';
import { LineItem } from '../../../core/orders/domain/LineItem';
import { ArticleLineItem } from './containers/ArticleLineItem/ArticleLineItem';
import { PopoverArticlesProps } from './PopoverArticlesProps';
import { PopoverArticlesState } from './PopoverArticlesState';

export class PopoverArticles extends React.Component<PopoverArticlesProps, PopoverArticlesState>{
    
    constructor(props: any){
        super(props);
        this.state = {
            active: false
        }
    }

    render() {
        return (            
            <Popover            
            fluidContent={true}              
            onClose={() => {
                this.setState({
                    active: false
                });
            }} active={this.state.active} activator={this.activator}>      
                {this.renderLineItems}                              
            </Popover>
        );
    }

    
    public get renderLineItems() : any {
        return this.props.lineItems.map((lineItem: LineItem) => {
            return (
                <ArticleLineItem shop={this.props.shop} key={lineItem.id} lineItem={lineItem}></ArticleLineItem>
            )
        });
    }
        
    public get articleQuantity() : number {
        return this.props.lineItems.map((e: any) => e.quantity).reduce((a, b) => a + b);
    }

    public get activator() : any {
        return (
            <Button onClick={() => {
                this.setState({ active: !this.state.active })
            }} disclosure>                
                {this.articleQuantity.toString()} Articulos
            </Button>
        )
    }


}