import { Shop } from "../../domain/Shop";
import { ShopRepository } from "../../domain/ShopRepository";

export class ShopValidChecker{
     private _repository: ShopRepository;
     constructor(repository: ShopRepository){
         this._repository = repository;
     }
     async invoke(shop: Shop){ 
        return this._repository.valid(shop);
     }
}