import { ActionList, Badge, Button, Card, Checkbox, DataTable, DatePicker, Heading, Modal, Pagination, Popover, ProgressBar, Range, SkeletonBodyText, SkeletonDisplayText, Spinner, Tabs, TextContainer, TextField } from '@shopify/polaris';
import React, { useCallback, useState } from 'react';
import { v1 } from 'uuid';

interface FieldPopoverProps{    
    title: string;
    onFilter(value: string): void;
}
interface FieldPopoverState{
    opened: boolean;
    filterValue: string;
}

class FieldPopover extends React.Component<FieldPopoverProps, FieldPopoverState>{
    private id: string;
    constructor(props: any){
        super(props);
        this.state = {
            opened: false,
            filterValue: ""
        }
        this.id = v1();
        this.togglePopoverActive = this.togglePopoverActive.bind(this);   
    }
    componentDidMount(){        
    }
    public get activator() : any {
        return (
            <Button monochrome={false} size={"medium"} outline={false} plain={true} onClick={this.togglePopoverActive} disclosure>
                {this.props.title}
            </Button>
        );
    }

    private togglePopoverActive(){
        this.setState({
            opened: !this.state.opened
        });
    }

    render(){ 
        return (
            <Popover
                fluidContent={true}
                key={this.id}
                active={this.state.opened}
                activator={this.activator}
                onClose={this.togglePopoverActive} >
                <form onSubmit={(e) => {
                    e.preventDefault();                    
                }} style={{padding: "16px"}}>                
                    <div style={{display: "flex", flexDirection: "row", margin: "8px"}}>
                        <TextField value={this.state.filterValue} onChange={(value: string) => {
                            this.setState({
                                filterValue: value
                            })
                        }} label={""} placeholder={"Filtrar por " + this.props.title}></TextField>
                        <Button submit={true} onClick={() => {
                            this.props.onFilter(this.state.filterValue);
                        }} primary={true}>Filtrar</Button>
                    </div>
                    {/* <div style={{display: "flex", flexDirection: "row", margin: "8px"}}>
                        <Button>Ascendente</Button>
                        <Button>Descendente</Button>
                    </div> */}
                </form>
          </Popover>
        )
    }
}


export default FieldPopover;