import { Shop } from "../../../shops/domain/Shop";
import { ProductRepository } from "../../domain/ProductRepository";

export class ProductOfShopLister{
    private _repository: ProductRepository;
    constructor(repository: ProductRepository){
        this._repository = repository;
    }
    invoke(e: Shop){
        return this._repository.ofShop(e);
    }
}