import { Shop } from "../../../shops/domain/Shop";
import { Order } from "../../domain/Order";
import { OrderRepository } from "../../domain/OrderRepository";
import { Tracking } from "../../domain/Tracking";

export class ApiOrderRepository extends OrderRepository { 
    private _url: string = "https://api.multistorefy.appsbealion.com/api/admin/order"
    public async ofShop(shop: Shop, sinceId?: Order | boolean): Promise<[Order[], Order | boolean]> {
        if(shop.confirmed == false){
            return [[], false];
        }
        let url = this._url + "/ofshop/" + shop.name;                
        if(sinceId instanceof Order){
                url = url + "/" + sinceId.id.toString();
        }        
        let response: Response = await fetch(url, {            
            headers: {
                'X-Token': localStorage.getItem("token") ?? ""
            }
        });        
        if(response.status !== 200){
            return [[] , false];
        }
        let json = await response.json();        
        let orders = json["orders"];
        let mappedOrders = orders.map((e: any) => Order.fromJson(e));
        return [
            mappedOrders , 
            json["last_order"] == false ? false : Order.fromJson(json["last_order"])
        ];
    }    

    public async addTracking(order: Order, tracking: Tracking){
        let formData = new FormData();
        formData.append("shop_url" , order.shop);
        formData.append("tracking_number" , tracking.trackingNumber);
        formData.append("tracking_url" , tracking.trackingUrl);
        formData.append("transport_name", tracking.transportName);
        if(tracking.fulfillmentId !== null){            
            formData.append("fulfillment_id" , tracking.fulfillmentId ?? "");            
        }
        let response: Response =  await fetch(this._url + "/" + order.id + "/tracking" , {
            method: "POST" , 
            body: formData, 
            headers: {
                'X-Token': localStorage.getItem("token") ?? ""
            }
        })
        if(response.status !== 200){
            throw new Error("Error");
        }
        return response;
    }

    public async ofId(id: string, shop: Shop): Promise<Order>{
        let url = this._url + "/ofid/" + shop.name + "/" + id;        
        let response: Response = await fetch(url, {            
            headers: {
                'X-Token': localStorage.getItem("token") ?? ""
            }
        });
        if(response.status !== 200){
            throw new Error("Error");
        }
        let json = await response.json();
        return Order.fromJson(json["order"]);
    }
    public async cancel(order: Order): Promise<any> { 
        let formData = new FormData();
        formData.append("shop_url" , order.shop);
        let response: Response = await fetch(this._url + "/" + order.id + "/cancel" , {
            method: "POST" , 
            body: formData, 
            headers: {
                'X-Token': localStorage.getItem("token") ?? ""
            }
        });
        if(response.status !== 200){
            throw new Error("Error");
        }
    }
}