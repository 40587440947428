import { Customer } from "../../customer/domain/Customer";
import { LineItem } from "./LineItem";
import { ShippingAddress } from "./ShippingAddress";

export class Order{
    private _appId: number;
    private _buyerAcceptsMarketing: boolean;
    private _cancelReason: string;
    private _cancelledAt: string | null;
    private _closedAt: string;
    private _confirmed: boolean;
    private _contactEmail: string;
    private _createdAt: string;
    private _customer: Customer;
    private _email: string;
    private _financialStatus: "paid" | "pending" | null;
    private _fulfillmentStatus: string | null;
    private _fullfillments: any[];
    private _lineItems: LineItem[];
    private _tags: string;
    private _id: number;
    private _name: string;
    private _phone: string;
    private _processedAt: string;
    private _shop: string;
    private _sourceName: string;
    private _subtotalPrice: string;
    private _shippingAddress: ShippingAddress | null ;
    private _token: string;
    private _updatedAt: string;
    private _selected: boolean = false;
    private _raw: any;
    private _currentTotalDiscounts: number;
    private _isCod: boolean = true;
    constructor(
        appId: number, 
        buyerAcceptsMarketing: boolean, 
        cancelReason: string, 
        cancelledAt: string | null, 
        closedAt: string, 
        confirmed: boolean, 
        contactEmail: string, 
        createdAt: string, 
        customer: Customer,
        email: string, 
        financialStatus: "paid" | "pending" | null, 
        fulfillmentStatus: string | null, 
        fulfillments: any[] , 
        lineItems: LineItem[],
        tags: string,
        id: number , 
        name: string, 
        phone: string, 
        processedAt: string, 
        shop: string, 
        sourceName: string, 
        subtotalPrice: string, 
        shippingAddress: ShippingAddress| null ,
        token: string, 
        updatedAt: string ,
        raw: any      ,
        currentTotalDiscounts: number,
        isCod: boolean
    ) { 
        this._appId = appId;
        this._buyerAcceptsMarketing = buyerAcceptsMarketing;
        this._cancelReason = cancelReason;
        this._cancelledAt = cancelledAt;
        this._closedAt = closedAt;
        this._confirmed = confirmed;
        this._contactEmail = contactEmail;
        this._createdAt = createdAt;
        this._customer = customer;
        this._email = email;
        this._financialStatus = financialStatus;
        this._fulfillmentStatus = fulfillmentStatus;
        this._fullfillments = fulfillments;
        this._lineItems = lineItems;
        this._tags = tags;
        this._id = id;
        this._name = name;
        this._phone = phone;
        this._processedAt = processedAt;
        this._shop = shop;
        this._sourceName = sourceName;
        this._subtotalPrice = subtotalPrice;
        this._shippingAddress = shippingAddress;
        this._token = token;
        this._updatedAt = updatedAt;
        this._selected = false;
        this._raw = raw;
        this._currentTotalDiscounts = currentTotalDiscounts;
        this._isCod = isCod;
    }
    public get appId(): number
    {
        return this._appId;
    }
    public get buyerAcceptsMarketing(): boolean
    {
        return this._buyerAcceptsMarketing;
    }
    public get cancelReason(): string
    {
        return this._cancelReason;
    }
    public get cancelledAt(): string | null
    {
        return this._cancelledAt;
    }
    public get closedAt(): string
    {
        return this._closedAt;
    }
    public get confirmed(): boolean
    {
        return this._confirmed;
    }
    public get contactEmail(): string
    {
        return this._contactEmail;
    }
    public get createdAt(): string
    {
        return this._createdAt;
    }
    
    public get orderNum() : number {
        var nameReplaced = this._name.replace('#' , '');
        var nameNumber = parseInt(nameReplaced)
        return nameNumber;
    }
    
    
    public get customer() : Customer {
        return this._customer;
    }
    
    public get email(): string
    {
        return this._email;
    }
    public get financialStatus(): "paid" | "pending" | null
    {
        return this._financialStatus;
    }
    public get fulfillmentStatus(): string | null
    {
        return this._fulfillmentStatus;
    }
    public get fullfillments(): any[]
    {
        return this._fullfillments;
    }
    public get id(): number
    {
        return this._id;
    }
    public get name(): string
    {
        return this._name;
    }
    public get phone(): string
    {
        return this._phone;
    }
    public get processedAt(): string
    {
        return this._processedAt;
    }
    public get shop(): string
    {
        return this._shop;
    }
    public get sourceName(): string
    {
        return this._sourceName;
    }
    public get subtotalPrice(): string
    {
        return this._subtotalPrice;
    }
    
    public get shippingAddress() : ShippingAddress | null {
        return this._shippingAddress;
    }
    
    public get token(): string
    {
        return this._token;
    }
    public get updatedAt(): string
    {
        return this._updatedAt;
    }
    
    public get lineItems() : LineItem[] {
        return this._lineItems;
    }
    
    public get tags() : string {
        return this._tags;
    }

    
    public get currentTotalDiscounts() : number {
        return this._currentTotalDiscounts;
    }
    
    
    public get selected() : boolean {
        return this._selected;
    }

    public toggleSelect(selected: boolean){        
        this._selected = selected;
    }
    
    
    public get isCancelled() : boolean {
        return this._cancelledAt != null
    }

    public cancel(){
        
    }
    
    public get notPayed(): boolean{
        return this._financialStatus == 'pending';
    }

    public get hasTracking() : boolean {
        return this._fullfillments.length > 0;
    }
    
    public get firstFullfillment(): any | null{
        if(!this.hasTracking){
            return null;
        }
        return this._fullfillments[0]["id"];
    }
    
    public get raw(): any{
        return this._raw;
    }

    
    public get dateFormatted() : string {
        let timestamp = Date.parse(this.createdAt);
        let date = new Date();
        date.setTime(timestamp);
        return Intl.DateTimeFormat('es-ES', {day: "2-digit", month: "2-digit", year: "numeric"}).format(date);
    }

    
    public get date() : Date {
        let timestamp = Date.parse(this.createdAt);
        let date = new Date();
        date.setTime(timestamp);
        return date;
    }
    public get isCod(): boolean{
        return this._isCod;
    }
    
    
    


    static fromJson(json: any){
        if(typeof json["customer"] == "undefined"){
            json["customer"] = new Customer("" , "invalid@mail.com" , "invalid", "invalid");
        }
        var isCod = json["processing_method"].length == 0 || json["processing_method"] == "manual";
        
        return new Order(
            json["app_id"] , 
            json["buyer_accepts_marketing"] , 
            json["cancel_reason"] , 
            json["cancelled_at"] , 
            json["closed_at"] , 
            json["confirmed"] , 
            json["contact_email"] , 
            json["created_at"] , 
            Customer.fromJson(json["customer"]),
            json["email"] , 
            json["financial_status"] , 
            json["fulfillment_status"] , 
            json["fulfillments"] , 
            json["line_items"].map((json: any) => LineItem.fromJson(json)),
            json["tags"],
            json["id"] , 
            json["name"] , 
            json["phone"] , 
            json["processed_at"] , 
            json["shop"] , 
            json["source_name"] , 
            json["subtotal_price"] , 
            json["shipping_address"] == null ? null : ShippingAddress.fromJson(json["shipping_address"]),
            json["token"] , 
            json["updated_at"],
            json,
            json["current_total_discounts"], 
            isCod
        );
    }

    toJson(): any{
        return {
            "app_id": this._appId , 
            "buyer_accepts_marketing": this._buyerAcceptsMarketing, 
            "cancel_reason": this._cancelReason, 
            "cancelled_at": this._cancelledAt , 
            "closed_at": this._closedAt, 
            "confirmed": this._confirmed, 
            "contact_email": this._contactEmail , 
            "created_at": this._createdAt,
            "customer": {
                "full_name": this.customer.fullName , 
                "email": this.customer.email , 
                "id": this.customer.id
            },
            "email": this._email , 
            "financial_status": this._financialStatus, 
            "fulfillments": this._fullfillments , 
            "line_items": this._lineItems.map((e: LineItem) => {
                return {
                    "name": e.id , 
                    "price": e.price , 
                    "product_id": e.productId , 
                    "quantity": e.quantity , 
                    "title": e.title , 
                    "variant_id": e.variantId , 
                    "fulfillment_status": e.fulfillmentStatus
                }                
            }), 
            "tags": this._tags , 
            "id": this._id , 
            "name": this._name , 
            "phone": this._phone , 
            "processed_at": this._processedAt , 
            "shop": this._shop , 
            "source_name": this._sourceName , 
            "subtotal_price": this._subtotalPrice , 
            "shipping_address": this._shippingAddress?.toJson() , 
            "token": this._token , 
            "updated_at": this._updatedAt, 
            "current_total_discounts": this._currentTotalDiscounts
        };
    }
    parseToString(){
        var string = "";
            string+=this._appId.toString();
            string+=this._buyerAcceptsMarketing;
            string+=this._cancelReason?.toLowerCase();
            string+=this._cancelledAt ?? "";
            string+=this._closedAt?.toLowerCase();
            string+=this._confirmed;
            string+=this._contactEmail?.toLowerCase();
            string+=this._createdAt.toLowerCase();
            string+=this._customer.fullName.toLowerCase();
            string+=this._customer.email?.toLowerCase();
            string+=this._email.toLowerCase();
            string+=this._financialStatus ?? "";
            // string+=this._fullfillments.map((e: any) => JSON.stringify(e));
            string+=this._tags.toLowerCase();
            string+=this._id.toString();
            string+=this._name.toLowerCase();
            string+=this._phone?.toLowerCase();
            string+=this._shop.toLowerCase();
            // string+=JSON.stringify(this.shippingAddress?.toJson());            
            return string;
    }


}