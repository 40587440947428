import React, { useCallback, useState } from 'react';



class OrdersApi{
    private _url: string = "https://api.multistorefy.appsbealion.com/api/admin/order"
    public getOrders(){                
        return fetch(this._url, {
            headers: {
                'X-Token': localStorage.getItem("token") ?? ""
            }
        });
    }

 


    public getAllOrders(){
        return fetch(this._url + "/all?tabfilter=tabfilter&filters[]=datefilter&filters[]=textFilter", {            
            headers: {
                'X-Token': localStorage.getItem("token") ?? ""
            }
        })
    }

    public cancelOrder(order: string, shop: string){
        let formData = new FormData();
        formData.append("shop_url" , shop);
        return fetch(this._url + "/" + order + "/cancel" , {
            method: "POST" , 
            body: formData, 
            headers: {
                'X-Token': localStorage.getItem("token") ?? ""
            }
        })
    }

    public getOrdersOfShop(shop: string, sinceId: string | null){
        let url = this._url + "/ofshop/" + shop;
        if(sinceId !== null){
            url = url + "/" + sinceId;
        }
        return fetch(url, {            
            headers: {
                'X-Token': localStorage.getItem("token") ?? ""
            }
        })
    }
    public getOrderOfId(shop: string, id: string){
        let url = this._url + "/ofid/" + shop + "/" + id;        
        return fetch(url, {            
            headers: {
                'X-Token': localStorage.getItem("token") ?? ""
            }
        })
    }

    public exportOrders(orders: any[]){
        let formData = new FormData();
        formData.append("orders" , JSON.stringify(orders));        
        return fetch(this._url + "/export" , {
            method: "POST" , 
            body: formData, 
            headers: {
                'X-Token': localStorage.getItem("token") ?? ""
            }
        })
    }

    public exportGLS(orders: any[]){
        let formData = new FormData();
        formData.append("orders" , JSON.stringify(orders));        
        return fetch(this._url + "/export-gls" , {
            method: "POST" , 
            body: formData, 
            headers: {
                'X-Token': localStorage.getItem("token") ?? ""
            }
        })
    }

    public addTracking(order: string, shop: string, trackingNumber: string, trackingUrl: string, transportName: string, fulfillmentId: string | null){
        let formData = new FormData();
        formData.append("shop_url" , shop);
        formData.append("tracking_number" , trackingNumber);
        formData.append("tracking_url" , trackingUrl);
        formData.append("transport_name", transportName);
        if(fulfillmentId !== null){
            formData.append("fulfillment_id" , fulfillmentId);
        }
        return fetch(this._url + "/" + order + "/tracking" , {
            method: "POST" , 
            body: formData, 
            headers: {
                'X-Token': localStorage.getItem("token") ?? ""
            }
        })
    }
}

export default OrdersApi;