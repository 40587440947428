import React, { useCallback, useState } from 'react';
import { Shop } from '../../core/shops/domain/Shop';

export interface MainState{
    page: Pages;
    shops: Shop[];
}



export enum Pages{
    ORDERS, SHOPS, TRANSPORTATION,LOADING , REPORTS
}

