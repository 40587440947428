import { Navigation } from '@shopify/polaris';
import React, { useCallback, useState } from 'react';
import { OrdersMajor,StoreMajor,LogOutMinor,ShipmentMajor } from '@shopify/polaris-icons';
import { DrawerProps } from './DrawerProps';
import { DrawerState } from './DrawerState';
import { Pages } from '../../MainState';


export class Drawer extends React.Component<DrawerProps, DrawerState> { 
    render() {
        return (
        <Navigation location="/">                
            <Navigation.Section items={this.buildItems} />            
        </Navigation>
        );
    }
    public get buildItems(){
        return [           
            {
                label: 'Pedidos',
                icon: OrdersMajor,                
                onClick: () => {
                    this.props.onChange(Pages.ORDERS);
                }
            },
            {
                label: 'Tiendas',
                icon: StoreMajor,                
                onClick: () => {
                    this.props.onChange(Pages.SHOPS);
                }
            },
            {
                label: 'Transportes',
                icon: ShipmentMajor,                
                onClick: () => {
                    this.props.onChange(Pages.TRANSPORTATION);
                }
            },
            {
                label: 'Salir',
                icon: LogOutMinor,                
                onClick: () => {
                    localStorage.removeItem("token");
                    window.location.href = "/start";
                }
            }
        ];
    }
}