import React, { useCallback, useState } from 'react';



class TokenApi{
    private _url: string = "https://api.multistorefy.appsbealion.com/api/admin/check-token"
    public checkToken():Promise<Response>{          
        return fetch(this._url, {
            headers: {
                'X-Token': localStorage.getItem("token") ?? ""
            }
        });
    }
}

export default TokenApi;