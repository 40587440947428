import { Button, Modal, Spinner, TextContainer, TextField } from '@shopify/polaris';
import React, { useCallback, useState } from 'react';
import TransportSelector, { Transport } from '../../../../../../components/transport-selector/Transport-selector';
import { ShopOrderOfIdFinder } from '../../../../../../core/orders/application/find/ShopOrderOfIdFinder';
import { TrackingOrderUpdater } from '../../../../../../core/orders/application/update/TrackingOrderUpdater';
import { Order } from '../../../../../../core/orders/domain/Order';
import { OrderRepository } from '../../../../../../core/orders/domain/OrderRepository';
import { Tracking } from '../../../../../../core/orders/domain/Tracking';
import { ApiOrderRepository } from '../../../../../../core/orders/infrastructure/api/ApiOrderRepository';
import { ProductStockUpdater } from '../../../../../../core/products/application/stock/product_stock_updater';
import { Product } from '../../../../../../core/products/domain/Product';
import { ProductRepository } from '../../../../../../core/products/domain/ProductRepository';
import { ApiProductRepository } from '../../../../../../core/products/infrastructure/api/ApiProductRepository';
import { OrderTrackingProps } from './OrderTrackingProps';
import { OrderTrackingState } from './OrderTrackingState';

export class OrderTracking extends React.Component<OrderTrackingProps, OrderTrackingState> {

    private _repository: OrderRepository = new ApiOrderRepository();
    private _productRepository: ProductRepository = new ApiProductRepository();
    private _trackingUpdater: TrackingOrderUpdater;
    private _orderIdFinder: ShopOrderOfIdFinder;
    private _productStockUpdater: ProductStockUpdater;
    constructor(props: any) {
        super(props);
        this.state = {
            modalOpened: false,
            trackingNumber: "",
            trackingUrl: "",
            transportName: "",
            saving: false
        }
        this._trackingUpdater = new TrackingOrderUpdater(this._repository);
        this._orderIdFinder = new ShopOrderOfIdFinder(this._repository);
        this._productStockUpdater = new ProductStockUpdater(this._productRepository);
        this.bindAll();
    }
    render() {
        return (
            <React.Fragment>
                <Button onClick={() => { this.setState({ modalOpened: true }) }} primary={this.isFulfilled} size={"slim"}>{this.trackingButtonText}</Button>
                <Modal open={this.state.modalOpened} onClose={() => {
                    this.setState({
                        modalOpened: false
                    })
                }} title="Añadir tracking">
                    <Modal.Section>
                        <TransportSelector onChange={this.selectTransportation}></TransportSelector>
                        <TextField focused={true} value={this.state.trackingNumber} onChange={this.changeTrackingNumber} label={"Número de seguimiento"} />
                        <TextContainer>
                            <a target="_blank" href=""></a>
                        </TextContainer>                        
                        <div style={{marginTop: "8px"}}>
                            <Button disabled={this.state.saving} onClick={this.saveTracking}>{this.saveButtonContent}</Button>
                        </div>                        
                    </Modal.Section>
                </Modal>
            </React.Fragment>
        );
    }

    public saveTracking(){
        this.setState({
            saving: true
        });
        let tracking: Tracking = {
            trackingNumber: this.state.trackingNumber , 
            trackingUrl: this.state.trackingUrl , 
            transportName: this.state.transportName , 
            fulfillmentId: this.props.order.firstFullfillment
        };
        this._trackingUpdater.invoke(this.props.order, tracking).then((value: any) => {
            this._orderIdFinder.invoke(this.props.order.id.toString(), this.props.shop).then((value: Order) => {                
                this.setState({
                    saving: false,
                    modalOpened: false , 
                    trackingNumber: ""
                }, async () => {
                    for(var lineItem of this.props.order.lineItems){
                        await this._productRepository.minusStock(lineItem.sku);
                    }                    
                    this.props.orderUpdated(value);
                });
                
            });            
        }).catch((e: any) => {
            this.setState({
                saving: false,
                modalOpened: false , 
                trackingNumber: ""
            }, () => {
                
            });
        });
    }
    public selectTransportation(value: Transport){
        this.setState({
            trackingUrl: value.defaultUrl,
            transportName: value.name
        });
    }
    public changeTrackingNumber(value: string){
        this.setState({
            trackingNumber: value
        });
    }
    public bindAll(){
        this.saveTracking         = this.saveTracking.bind(this);
        this.selectTransportation = this.selectTransportation.bind(this);
        this.changeTrackingNumber = this.changeTrackingNumber.bind(this);
    }
        
    public get saveButtonContent() : any {
        if(this.state.saving == true){
            return (
                <Spinner size="small"></Spinner>
            )
        }
        return "Guardar";
    }


    public get trackingButtonText(): string {
        return this.isFulfilled ? "Modificar tracking" : "Añadir tracking";
    }


    public get isFulfilled(): boolean {
        let isFulfilled = this.props.order.fullfillments.length > 0;
        return isFulfilled;
    }

}