import { Button, Card, Form, Spinner, TextField } from '@shopify/polaris';
import React, { useCallback, useState } from 'react';
import { AdminAuth } from '../../core/admin/application/auth/AdminAuth';
import { Admin } from '../../core/admin/domain/Admin';
import { AdminRepository } from '../../core/admin/domain/AdminRepository';
import { ApiAdminRepository } from '../../core/admin/infrastructure/api/ApiAdminRepository';
import { AuthProps } from './AuthProps';
import { AuthState, Error } from './AuthState';


export class Auth extends React.Component<AuthProps, AuthState>{
    private _repository: AdminRepository = new ApiAdminRepository();
    private _authenticator: AdminAuth;
    constructor(props: any){
        super(props);
        this.state = {
            email: "" , 
            password: "" ,             
            error: Error.NO_ERROR,
            logging: false
        }
        this._authenticator = new AdminAuth(this._repository);
        this.bindAll();
    }

    public render(){
        return (        
            <Card title={this.title}>          
                <Form onSubmit={this.submitForm}>
                    <div className="register-form">
                        <TextField label="Correo electrónico" error={this.invalidCredentials}  value={this.state.email} onChange={(e: string) => this.setState({ email: e })} type="email"></TextField>
                        <TextField helpText={this.invalidCredentialsText} error={this.invalidCredentials} label="Contraseña" value={this.state.password} onChange={(e: string) => this.setState({ password: e })} type="password"></TextField>                                        
                        <div className="register-button">
                            <Button disabled={this.state.logging} size={'large'} onClick={this.submitForm} primary={true} fullWidth={true} submit>{this.loggingButtonContent}</Button>
                        </div>             
                        <div className="register-button">
                            <Button disabled={this.state.logging} size={'large'} onClick={this.props.onRegister} fullWidth={true}>Registrarse</Button>
                        </div>
                    </div>
                </Form>                  
            </Card>
        );
    }
    public bindAll(){
        this.submitForm = this.submitForm.bind(this);        
    }

    
    public get loggingButtonContent() : any {
        if(this.state.logging == true){
            return (
                <Spinner size="small"></Spinner>
            )
        }
        return "Iniciar sesión";
    }
    
    

    public async submitForm(){
        
        try{
            this.setState({
                logging: true
            });
            let admin: Admin = await this._authenticator.invoke(this.state.email, this.state.password);
            localStorage.setItem("token", admin.token);
            localStorage.setItem("email", this.state.email);
            this.props.onSuccessAuth();
        }catch(e: any){
             this.setState({
                 error: Error.INVALID_CREDENTIALS, 
                 logging: false
             });
        }
    }

    public get invalidCredentialsText(): any{
        if(this.invalidCredentials){
            return (
                <div style={{color: "red", textAlign: "left"}}>Credenciales invalidas</div>
            )
        }
        return (
            <div></div>
        )
    }
    public get invalidCredentials() : boolean {
        return this.state.error == Error.INVALID_CREDENTIALS;
    }
    

    public get title() : any {
        return ( <h1 style={{fontSize: "24px", fontWeight: "bold"}}>Inicia sesión en multistorefy</h1>)
    }

}