import { Card, Tabs } from '@shopify/polaris';
import React, { useCallback, useState } from 'react';
import ShopsApi from '../../data/shops/shops_api';
import { Shop } from '../../models/shop';
import { ShopSelector } from '../Shared/ShopSelector/ShopSelector';
import { FiltersForm } from './containers/FiltersForm/FiltersForm';
import { Filter, OrdersTable } from './containers/OrdersTable/OrdersTable';
import { OrdersProps } from './OrdersProps';
import { OrdersState } from './OrdersState';
import { TabFilter } from './TabFilter';




export class Orders extends React.Component<OrdersProps, OrdersState> {    
    private _orderTableRef: React.RefObject<OrdersTable>;
    constructor(props: any) {

        super(props);       
        this._orderTableRef = React.createRef();
        this.state = {
            shops: [] ,    
            filter: {
                global: "" , 
                shop: "", 
                article: "" , 
                orderName: "" , 
                tags: "" , 
                customer: "" , 
                address: "" , 
                tracking: "" , 
                date: {
                  start: new Date(new Date().setMonth(0)),
                  end: new Date(new Date().setHours(23, 59, 59, 999)),
                },
                tab: TabFilter.all
            } 
        }
    }

    componentDidMount(){
      this.setState({
        filter: {
          global: "" , 
          shop: "", 
          article: "" , 
          orderName: "" , 
          tags: "" , 
          customer: "" , 
          address: "" , 
          tracking: "" , 
          date: {
            start: new Date(new Date().setMonth(0)),
            end: new Date(new Date().setHours(23, 59, 59, 999)),
          },
          tab: TabFilter.all
      } 
      })
    }
    
    public get tabs() : any {
        return  [
            {
              id: 'all-orders-1',
              content: 'Todos',
              value: TabFilter.all,
              accessibilityLabel: 'All orders',
              panelID: 'all-orders-content-1',
            },
            {
              id: 'opened-orders-1',
              content: 'Abiertos',
              value: TabFilter.opened,
              panelID: 'opened-orders-content-1',
            },
            {
              id: 'canceled-orders-1',
              content: 'Cancelados',
              value: TabFilter.cancelled,
              panelID: 'canceled-orders-content-1',
            },
            {
              id: 'not-paids-1',
              content: 'No Pagados',
              value: TabFilter.notpayed,
              panelID: 'not-paids-content-1',
            },
            {
              id: 'not-tracked-1',
              content: 'Sin tracking',
              value: TabFilter.notracking,
              panelID: 'not-tracked-content-1',
            },
            {
              id: 'tracked-1',
              content: 'Con tracking',
              value: TabFilter.withtracking,
              panelID: 'tracked-content-1',
            },
          ];
    }

    componentDidUpdate(){
            
    }
    
    
    
    

    render(){
        return (
            <div>
                <FiltersForm filter={this.state.filter} 
                  onFilterChange={this.debounce((filter: Filter) => {                    
                    this.setState({ filter: filter });
                  }, 500)} shops={this.props.shops}></FiltersForm>
                  <div style={{margin: "8px"}}>
                    <Card>
                        <Tabs tabs={this.tabs} selected={this.state.filter.tab.valueOf()} onSelect={(value: number) => {                        
                                let tab = this.tabs[value];                            
                                this.setState({
                                    filter: {
                                        tab: tab.value, 
                                        article: this.state.filter.article, 
                                        address: this.state.filter.address, 
                                        customer: this.state.filter.customer, 
                                        orderName: this.state.filter.orderName, 
                                        shop: this.state.filter.shop, 
                                        tags: this.state.filter.tags,
                                        tracking: this.state.filter.tracking,
                                        date: {
                                          start: this.state.filter.date.start,
                                          end:  this.state.filter.date.end,
                                        },
                                        global: this.state.filter.global
                                    }
                                }, () => {
                                  this._orderTableRef.current?.resetPage();   
                                })     
                                            
                            }}>                        
                        </Tabs>
                        <OrdersTable ref={this._orderTableRef}  onFilterChange={(filter: Filter) => { 
                          this.setState({ filter: filter }); 
                        }} filter={this.state.filter} shops={this.props.shops}></OrdersTable>
                    </Card>
                </div>
            </div>
        );        
    }
    debounce(callback: any, wait: any) {
      let timerId: any;
      return (...args: any) => {
        clearTimeout(timerId);
        timerId = setTimeout(() => {
          callback(...args);
        }, wait);
      };
    }
}
